<template>
  <v-card flat color="transparent" class="pb-2">
    <v-card
      v-for="item in myItems"
      :key="item.id"
      class="pa-0 ma-0"
      flat
      color="transparent"
    >
      <v-container fluid class="pa-0 ma-0" style="height:100%">
        <v-row class="pa-0 ma-0" align="center">
          <v-card width="80" class="pa-0 ma-0" flat color="transparent">
            <v-checkbox
              v-model="item.check"
              class="ml-7"
              @change="check(item)"
            ></v-checkbox
          ></v-card>
          <v-col class="pa-0 ma-0">
            <v-card
              :elevation="1"
              class="mt-2 mr-2 pa-2"
              :color="item.check ? '#E2EEFC' : ''"
              ><v-container fluid class="pa-0 ma-0" style="height:100%">
                <v-row class="pa-0 ma-0" align="center">
                  <v-card outlined width="110" height="110" class="pa-1">
                    <v-skeleton-loader
                      v-if="item.image == undefined || item.image == null"
                      width="100"
                      max-height="100"
                      type="image"
                    ></v-skeleton-loader>
                    <v-img
                      v-if="
                        item.image !== undefined &&
                          item.image !== null &&
                          item.image !== 'notFound'
                      "
                      :lazy-src="item.image"
                      :src="item.image"
                      contain
                      max-height="100"
                      max-width="100"
                    ></v-img>
                    <v-img
                      v-if="item.image == 'notFound'"
                      :lazy-src="noImage"
                      :src="noImage"
                      style="background-color: #F0F0F0; opacity: 0.45"
                      contain
                      height="100"
                      width="100"
                    ></v-img>
                  </v-card>
                  <v-col class="ma-0 pa-0">
                    <v-card flat color="transparent" height="100" class="pt-2">
                      <v-card-text
                        style="font-weight: 600; font-size:18px;"
                        :class="
                          item.check
                            ? 'pt-2 pb-1 primary--text'
                            : 'pt-2 pb-1 black--text'
                        "
                        >{{ item.name }}
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-if="!item.isPublic"
                              v-on="on"
                              small
                              style="margin-top: -3px; margin-left: 1px; margin-right: 2px"
                              >mdi-lock</v-icon
                            ></template
                          >
                          <span>權限不公開</span>
                        </v-tooltip>
                        <v-btn
                          v-if="item.publishVersionId !== null"
                          outlined
                          color="primary"
                          disabled
                          width="56"
                          small
                          id="custom-disabled"
                          style="margin-top:-4px; margin-left:4px"
                          >版本 {{ item.publishVersionNumber }}
                        </v-btn>
                        <v-btn
                          v-if="item.publishVersionNumber == null"
                          outlined
                          color="primary"
                          disabled
                          width="56"
                          small
                          id="custom-disabledPublished"
                          style="margin-top:-4px; margin-left:4px"
                          >未發布
                        </v-btn>
                      </v-card-text>
                      <v-card-text class="pb-0 pt-4"
                        >查閱次數：<v-chip
                          v-if="item.viewCounts !== undefined"
                          style="font-weight: 600;"
                          small
                        >
                          {{ item.viewCounts }}
                        </v-chip>
                        <v-progress-circular
                          v-if="item.viewCounts == undefined"
                          :size="18"
                          :width="3"
                          color="#B1B1B1"
                          indeterminate
                          class="mt-1 mr-3"
                        ></v-progress-circular></v-card-text
                    ></v-card>
                  </v-col>
                </v-row>
              </v-container> </v-card
          ></v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card v-if="!loading && myItems.length == 0" flat color="transparent"
      ><EmptyCard
    /></v-card>
  </v-card>
</template>

<script>
import { cloneDeep } from 'lodash';
import EmptyCard from './EmptyCard';
export default {
  components: {
    EmptyCard,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
  watch: {
    items: {
      handler() {
        this.loadItems();
      },
      deep: true,
    },
  },
  data() {
    return {
      myItems: [],
      movingItems: [],
      noImage: require('../../../assets/noImage.svg'),
      deleteDialog: {
        value: false,
        deleteItem: {},
      },
      loadingColor: 'primary',
      selectAllCheck: false,
    };
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    loadItems() {
      var myItems = [];
      var items = cloneDeep(this.items);
      for (let i = 0; i < items.length; i++) {
        if (this.items[i].isEnable) {
          myItems.push(items[i]);
        }
      }

      this.myItems = myItems;
    },
    getMoveingItems() {
      var movingItems = [];
      for (let i = 0; i < this.myItems.length; i++) {
        if (this.myItems[i].check) {
          movingItems.push(this.myItems[i]);
        }
      }
      this.movingItems = movingItems;

      if (this.movingItems.length !== 0) {
        this.$emit('moveMultiDialogOpen', this.movingItems);
      }
    },
    check(item) {
      this.$emit('checkChange', item);
    },
  },
};
</script>

<style lang="scss" scoped>
#custom-disabled.v-btn--disabled {
  color: #006ad8 !important;
}
#custom-disabledPublished.v-btn--disabled {
  color: #7a7a7a !important;
}
</style>
