<template>
  <div>
    <v-card>
      <v-card-title>新增版本</v-card-title>
      <v-divider class="mb-3" />
      <sb-ui-uploader
        class="pl-5 pr-3 mb-1"
        :files.sync="files"
        :multiple="multiple"
        :loading="loading"
        accept=".zip"
      />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="uploadFileClose()">{{
          $t("form.actions.cancel")
        }}</v-btn>
        <v-btn dark color="primary" @click="uploadFileConfirm()">
          {{ $t("form.actions.ok") }}新增</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      files: [],
      multiple: false,
      loading: false,
    };
  },
  methods: {
    uploadFileClose() {
      this.files = [];
      this.loading = false;
      this.$emit("uploadFileClose");
    },
    uploadFileConfirm() {
      this.loading = true;
      this.$emit("uploadFileConfirm", this.files);
    },
    finishUpload() {
      this.files = [];
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles.scss";
</style>
