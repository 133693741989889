<template>
  <v-container fluid class="pa-0 ma-0" style="height:100%">
    <v-row class="pa-0 ma-0" style="height:100%;">
      <!-- Product Group  左側：零組件群組列表  -->
      <v-col class="pa-1 ma-0" style="height:100% " :cols="clicked ? 4 : 12">
        <LeftCard
          ref="leftCard"
          :sorting="sorting"
          :companyId="companyId"
          :uploadGroup="uploadGroup"
          :openVersion="openVersion"
          :groupIdOpen="groupIdOpen"
          :readOnly="readOnly"
          @getProductItems="getProductItems"
          @productClear="productClear"
          @searchItem="searchItem"
          @searchEnd="searchEnd"
          @addGroupToLocation="addGroupToLocation"
          @forbiddenOpen="forbiddenOpen"
          @searchDisabledChange="searchDisabledChange"
        />
      </v-col>
      <!-- Product Item  右側：群組內零件列表  -->
      <v-col style="height:100%" class="pa-1 ma-0" v-if="clicked">
        <RightCard
          ref="rightCard"
          :companyId="companyId"
          :product="product"
          :items="items"
          :searchText="searchText"
          :itemIdOpen="myItemIdOpen"
          :isEnable="isEnable"
          :readOnly="readOnly"
          @reload="reload"
          @loadingEnd="loadingEnd"
          @viewVersions="viewVersions"
          @addIsEnableToLocation="addIsEnableToLocation"
          @removeIsEnableFromRoute="removeIsEnableFromRoute"
        />
      </v-col>
      <v-overlay
        id="overlay"
        color="#F3F3F3"
        :absolute="absolute"
        :opacity="opacity"
        :value="overlay"
        :dark="false"
      >
        <Version
          ref="versionOverlay"
          :style="size"
          :companyId="companyId"
          :item="openItem"
          :product="product"
          :itemIdOpen="myItemIdOpen"
          :versionIdOpen="myVersionIdOpen"
          :isFullScreen="isFullScreen"
          :readOnly="readOnly"
          @reloadItem="reloadItem"
          @closeVersion="closeVersion"
          @viewVersions="viewVersions"
          @addVersionToLocation="addVersionToLocation"
        />
      </v-overlay>
    </v-row>
  </v-container>
</template>

<script>
import LeftCard from './LeftCard';
import RightCard from './RightCard/RightCard';
import Version from './VersionCard/Main';
export default {
  components: {
    LeftCard,
    RightCard,
    Version,
  },
  props: {
    companyId: {
      type: String,
      required: true,
    },
    uploadGroup: {
      type: Object,
    },
    openVersion: {
      type: Object,
    },
    isFullScreen: {
      type: Boolean,
    },
    groupIdOpen: {
      type: String,
    },
    itemIdOpen: {
      type: String,
    },
    versionIdOpen: {
      type: String,
    },
    isEnable: {
      type: String,
    },
    readOnly: {
      type: Boolean,
    },
  },
  data() {
    return {
      clicked: false,
      product: {},
      absolute: true,
      opacity: 1,
      overlay: false,
      size: '',
      groupId: '',
      originalItems: [],
      items: [],
      openItem: {},
      dataState: {
        page: 0,
      },
      sorting: 1,
      searchText: null,
      routeGroup: null,
      routeItem: null,
      routeVersion: null,
      routeisEnable: null,
      myItemIdOpen: null,
      myVersionIdOpen: null,
    };
  },
  created() {
    window.addEventListener('resize', this.getSize);
  },
  destroyed() {
    window.removeEventListener('resize', this.getSize);
  },
  watch: {
    itemIdOpen: {
      handler(val) {
        if (val) {
          this.myItemIdOpen = val;
          this.addItemToLocation(val);
        }
      },
      deep: true,
    },
    companyId: {
      handler() {
        this.clicked = false;
        this.overlay = false;
      },
      deep: true,
    },
  },
  mounted() {
    if (this.openVersion !== undefined) {
      this.overlay = true;
    }
    if (this.versionIdOpen) {
      this.overlay = true;
      this.myVersionIdOpen = this.versionIdOpen;
    }
    if (this.itemIdOpen) {
      this.myItemIdOpen = this.itemIdOpen;
    }
  },
  methods: {
    /**紀錄選取group item version isEnable(停用)*/
    addGroupToLocation(params) {
      history.pushState(
        {},
        null,
        this.$route.path + '?group=' + encodeURIComponent(params.id)
      );
      this.routeGroup = '?group=' + encodeURIComponent(params.id);
    },
    addItemToLocation(id) {
      if (this.routeisEnable == null) {
        history.pushState(
          {},
          null,
          this.$route.path + this.routeGroup + '&item=' + encodeURIComponent(id)
        );
      } else {
        history.pushState(
          {},
          null,
          this.$route.path +
            this.routeGroup +
            this.routeisEnable +
            '&item=' +
            encodeURIComponent(id)
        );
      }
      this.routeItem = '&item=' + encodeURIComponent(id);
    },
    addVersionToLocation(params) {
      if (this.routeisEnable == null) {
        history.pushState(
          {},
          null,
          this.$route.path +
            this.routeGroup +
            this.routeItem +
            '&version=' +
            encodeURIComponent(params.id)
        );
      } else {
        history.pushState(
          {},
          null,
          this.$route.path +
            this.routeGroup +
            this.routeisEnable +
            this.routeItem +
            '&version=' +
            encodeURIComponent(params.id)
        );
      }
      this.routeVersion = '&version=' + encodeURIComponent(params.id);
    },
    addIsEnableToLocation(params) {
      history.pushState(
        {},
        null,
        this.$route.path +
          this.routeGroup +
          '&isEnable=' +
          encodeURIComponent(params)
      );
      this.routeisEnable = '&isEnable=' + encodeURIComponent(params);
    },
    removeIsEnableFromRoute() {
      this.routeisEnable = null;
      history.pushState({}, null, this.$route.path + this.routeGroup);
    },
    /**更新群組 */
    reloadGroup() {
      this.$refs.leftCard.loadGroups();
    },
    getProductItems(product) {
      // console.log("product", product.name);
      this.product = product;
      this.clicked = true;
    },
    productClear() {
      this.clicked = false;
      history.pushState({}, null, this.$route.path);
      this.routeGroup = null;
    },
    viewVersions(item, versionOpen, upload, apply) {
      if (this.myVersionIdOpen == null) {
        this.addItemToLocation(item.id);
        this.openItem = item;
        if (!versionOpen) {
          this.overlay = true;
        } else if (this.overlay) {
          this.$refs.versionOverlay.finishReload(upload, apply);
        }
        this.getSize();
      }
    },
    /**因應用版次改變而reload查閱版本overlay */
    reloadItem(upload, apply) {
      this.$refs.rightCard.loadItems(this.product, upload, apply);
    },
    /**動態設定overlay中的card大小 */
    getSize() {
      var height = document.getElementById('overlay').clientHeight;
      var width = document.getElementById('overlay').clientWidth;
      if (this.isFullScreen) {
        width = document.getElementById('overlay').clientWidth - 13;
      }

      //return `width:${width}px;height:${height}px;`;
      this.size = `width:${width}px;height:${height}px;`;
    },
    closeVersion() {
      this.overlay = false;
      this.$refs.rightCard.closeVersion();
      this.myItemIdOpen = null;
      this.myVersionIdOpen = null;
      this.routeItem = null;
      this.routeVersion = null;
      if (this.routeisEnable == null) {
        history.pushState({}, null, this.$route.path + this.routeGroup);
      } else {
        history.pushState(
          {},
          null,
          this.$route.path + this.routeGroup + this.routeisEnable
        );
      }
    },
    sortGroup() {
      this.sorting = -this.sorting;
    },
    search(e) {
      this.searchText = e;
      this.$refs.leftCard.search(e);
    },
    searchEnd() {
      this.clicked = false;
    },
    searchItem(searchText, product) {
      var openProduct = product;
      if (product == undefined) {
        openProduct = this.product;
      }

      this.$refs.rightCard.searchItem(searchText, openProduct);
    },
    /**loading時禁止搜尋，避免產生錯誤*/
    searchDisabledChange(disabled) {
      this.$emit('searchDisabledChange', disabled);
    },
    /**進行刪除等動作時重新讀取 */
    async reload(group) {
      await this.$refs.leftCard.loadGroups();
      this.$refs.leftCard.routeGroup(group);
    },
    loadingEnd() {
      this.$refs.leftCard.loadingEnd();
    },
    forbiddenOpen() {
      this.$emit('forbiddenOpen');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles.scss';
</style>
