var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"transparent","flat":""}},[(_vm.loadingVersion)?_c('SkeletonVersionList'):_vm._e(),(!_vm.loadingVersion)?_c('v-timeline',{staticStyle:{"margin-left":"-25px"},attrs:{"dense":""}},_vm._l((_vm.versions),function(item){return _c('v-timeline-item',{key:item.id,staticClass:"ma-0",attrs:{"small":"","icon":item.apply ? 'mdi-check' : '',"color":item.apply ? 'primary' : '#C4C4C4',"fill-dot":item.apply ? true : false}},[_c('div',[_c('v-card',{staticClass:"pt-0 ma-0",attrs:{"width":"258","elevation":item.active ? '4' : '2',"color":item.active ? '#E0EFFF' : '#F7F7F7'},on:{"click":function($event){return _vm.clickVersion(item)}}},[_c('v-container',{staticClass:"pa-0 ma-0",staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-card',{staticStyle:{"width":"100%"},attrs:{"flat":"","color":"transparent"}},[_c('v-card-title',{staticClass:"pt-2 pl-3 pb-1 pr-1 ma-0 subtitle-1"},[_vm._v("版本 "+_vm._s(item.versionNumber)),(!item.isPreprocessing)?_c('v-badge',{staticClass:"ma-0",attrs:{"content":item.finished !== undefined
                        ? item.finished + '/' + item.totalModelCount
                        : '讀取中',"inline":""}},[_c('v-chip',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("不預處理")])],1):_vm._e(),(item.isPreprocessing)?_c('v-badge',{staticClass:"ma-0",attrs:{"content":item.finished !== undefined
                        ? item.finished + '/' + item.totalModelCount
                        : '讀取中/' + item.totalModelCount,"inline":""}},[_c('v-chip',{staticClass:"ml-2",attrs:{"small":"","color":"success"}},[_vm._v("預處理"),(!item.isAllowedCreateOnDemand)?_c('v-icon',{staticClass:"ml-1",attrs:{"x-small":""}},[_vm._v("mdi-lock")]):_vm._e()],1)],1):_vm._e(),_c('v-spacer'),(_vm.isSystemAdmin)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.downloadVersion(item)}}},on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v("下載此版本")])]):_vm._e()],1)],1)],1)],1),_c('v-divider'),_c('v-container',{staticClass:"pa-0 ma-0",staticStyle:{"height":"100%","text-align":"center"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-2 ma-0"},[_c('v-card',{staticClass:"pa-1 mr-2 align-start",attrs:{"outlined":"","height":"90","width":"90"}},[(item.image == undefined || item.image == null)?_c('v-skeleton-loader',{staticStyle:{"margin-top":"1px","margin-bottom":"1px","margin-left":"1px"},attrs:{"width":"78","height":"78","type":"image"}}):_vm._e(),(
                    item.image !== undefined &&
                    item.image !== null &&
                    item.image !== 'notFound'
                  )?_c('v-img',{staticStyle:{"margin-top":"1px"},attrs:{"lazy-src":item.image,"src":item.image,"contain":"","height":"78","width":"78"}}):_vm._e(),(item.image == 'notFound')?_c('v-img',{staticStyle:{"background-color":"#f0f0f0","opacity":"0.7","padding-top":"35px","margin-top":"1px","margin-left":"1px"},attrs:{"lazy-src":_vm.noImage,"src":_vm.noImage,"contain":"","height":"78","width":"78"}}):_vm._e()],1),_c('v-card',{staticClass:"timelineTextCard mr-3",attrs:{"flat":"","color":"transparent"}},[_c('v-card-text',{staticClass:"timelineText"},[_vm._v(" "+_vm._s(item ? item.createdUserName : ''))]),_c('v-card-text',{staticClass:"timelineTime"},[_vm._v(_vm._s(item.createdDay)+" "+_vm._s(item.createdTime))]),(item.createdDate)?_c('v-card-text',{staticClass:"timelineTime"},[_c('sb-ui-time-cell',{attrs:{"time":item.createdDate}})],1):_vm._e(),_c('v-card-text',{staticClass:"timelineTime"},[_vm._v("查閱次數："),_c('v-chip',{staticStyle:{"font-weight":"600"},attrs:{"small":""}},[_vm._v(" "+_vm._s(item.viewCount)+" ")])],1)],1)],1)],1)],1)],1)])}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }