<template>
  <div>
    <v-card>
      <v-card-title>刪除元件</v-card-title>
      <v-card-text>{{
        $t("form.form.sureToDelete") + " " + deleteItem.name + " 嗎?"
      }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="delDialogClose()">{{
          $t("form.actions.cancel")
        }}</v-btn>
        <v-btn dark color="error" @click="deleteItemConfirm()">{{
          $t("form.actions.delete")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    deleteItem: {
      type: Object,
    },
  },
  data() {
    return {
      deleteDialog: {
        value: false,
        dataItem: {},
      },
    };
  },
  methods: {
    delDialogClose() {
      this.$emit("delDialogClose");
    },
    deleteItemConfirm() {
      this.$emit("deleteItemConfirm");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles.scss";
</style>
