<template>
  <div>
    <v-card>
      <v-card-title>沿用分享碼</v-card-title>
      <v-card-text>{{ '是否沿用前一版本之分享碼?' }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="inheritDialogClose()">{{ $t('不沿用') }}</v-btn>
        <v-btn dark color="primary" @click="inheritDialogComfirm()">{{
          $t('確定沿用')
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    deleteItem: {
      type: Object,
    },
  },
  data() {
    return {
      deleteDialog: {
        value: false,
        dataItem: {},
      },
    };
  },
  methods: {
    inheritDialogClose() {
      this.$emit('inheritDialogClose');
    },
    inheritDialogComfirm() {
      this.$emit('inheritDialogComfirm');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles.scss';
</style>
