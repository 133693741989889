var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","color":"transparent"}},[(_vm.version.status == 0 && _vm.version.isPreprocessing)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3",attrs:{"fab":"","small":"","elevation":"0","height":"34","width":"34","color":"#D1E7FF"},on:{"click":function($event){return _vm.pendingDialogOpen()}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-play")])],1)]}}],null,false,1444091441)},[_c('span',[_vm._v("提交")])]):_vm._e(),(_vm.version.status == 6)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3",attrs:{"fab":"","small":"","elevation":"0","height":"34","width":"34","disabled":_vm.statusChangeLoading},on:{"click":function($event){return _vm.pauseDialogOpen()}}},on),[_c('v-icon',{attrs:{"color":"#7D7D7D"}},[_vm._v("mdi-pause")])],1)]}}],null,false,3357045707)},[_c('span',[_vm._v("暫停")])]):_vm._e(),(_vm.version.status == 2)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3",attrs:{"fab":"","small":"","elevation":"0","height":"34","width":"34","color":"#D1E7FF","disabled":_vm.statusChangeLoading},on:{"click":function($event){return _vm.continueDialogOpen()}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-play")])],1)]}}],null,false,3896497341)},[_c('span',[_vm._v("繼續")])]):_vm._e(),(_vm.version.status == 3)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3",attrs:{"fab":"","small":"","elevation":"0","height":"34","width":"34","color":"primary","disabled":_vm.statusChangeLoading},on:{"click":function($event){return _vm.retryDialogOpen()}}},on),[_c('v-icon',[_vm._v("mdi-replay")])],1)]}}],null,false,3883539062)},[_c('span',[_vm._v("重試")])]):_vm._e(),(_vm.version.status == 4)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3",attrs:{"fab":"","small":"","elevation":"0","height":"34","width":"34","color":"primary","disabled":_vm.statusChangeLoading},on:{"click":function($event){return _vm.enableDialogOpen()}}},on),[_c('v-icon',[_vm._v("mdi-keyboard-return")])],1)]}}],null,false,3181768262)},[_c('span',[_vm._v("啟用")])]):_vm._e(),(_vm.version.status !== 4 && _vm.version.status !== 5)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3",attrs:{"fab":"","small":"","elevation":"0","color":"#FDECEC","height":"34","width":"34","disabled":_vm.statusChangeLoading},on:{"click":function($event){return _vm.voidDialogOpen()}}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete-outline")])],1)]}}],null,false,979438697)},[_c('span',[_vm._v("作廢")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }