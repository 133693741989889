<template>
  <td style="text-align: center;">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          :id="'btn_' + dataItem.id"
          color="primary"
          icon
          class="mr-2"
          :disabled="stateCheck()"
          @click="openForge()"
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </template>

      <span>檢視內容</span>
    </v-tooltip>

    <v-menu offset-y left>
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
              :id="'btn_' + dataItem.id"
              color="primary"
              icon
              :disabled="stateCheck()"
              @click="download"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>

          <span>下載</span>
        </v-tooltip></template
      >
      <v-list subheader flat dense class="pa-0">
        <v-subheader>選擇檔案格式</v-subheader>
        <v-divider />
        <v-list-item-group v-model="downloadType" color="primary">
          <!-- <v-list-item>
            <v-list-item-content @click.stop>
              <v-list-item-title>IPT</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <v-list-item>
            <v-list-item-content @click="iptDownload()">
              <v-list-item-title>IPT</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content @click="stpDownload()">
              <v-list-item-title>STEP</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content @click="dxfDownload()">
              <v-list-item-title>DXF</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item>
            <v-list-item-content @click.stop>
              <v-list-item-title>PDF</v-list-item-title>
            </v-list-item-content>
          </v-list-item> 
          <v-divider />
          <v-list-item>
            <v-list-item-content @click.stop>
              <v-list-item-title>全部下載</v-list-item-title>
            </v-list-item-content>
          </v-list-item>-->
        </v-list-item-group>
      </v-list>
    </v-menu>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          :id="'btn_' + dataItem.id"
          color="primary"
          icon
          class="mr-2"
          :disabled="dataItem.link == null"
          @click="copyLink()"
          ><v-icon>mdi-link</v-icon></v-btn
        >
      </template>

      <span>取得連結</span>
    </v-tooltip>
  </td>
</template>

<script>
export default {
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
  },
  data() {
    return {
      downloadType: '',
    };
  },
  methods: {
    openForge() {
      this.$emit('openForge', { dataItem: this.dataItem });
    },
    download() {
      this.$emit('download', { dataItem: this.dataItem });
    },
    stateCheck() {
      if (this.dataItem.status == 5) {
        return false;
      } else {
        return true;
      }
    },
    iptDownload() {
      this.$emit('iptDownload', { dataItem: this.dataItem });
    },
    dxfDownload() {
      this.$emit('dxfDownload', { dataItem: this.dataItem });
    },
    stpDownload() {
      this.$emit('stpDownload', { dataItem: this.dataItem });
    },
    copyLink() {
      const clipboardy = require('clipboardy');
      clipboardy.write(this.dataItem.link);
      let msg = '已複製連結';
      this.$store.commit('notification/SET_ALERT', {
        type: 'success',
        message: msg,
        value: true,
      });
    },
  },
};
</script>

<style></style>
