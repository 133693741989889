<template>
  <div>
    <v-card>
      <v-card-title
        >批次移動下列零組件至<span style="font-weight: 650">
          {{ selectedGName }}</span
        ></v-card-title
      >
      <v-divider />

      <v-card flat max-height="400" min-width="140" style="overflow-y: scroll;">
        <v-container class="pa-0 ma-0">
          <v-row v-for="item in movingItems" :key="item.id" class="pa-0 ma-0"
            ><v-card flat
              ><v-card-text
                class="pa-3 ml-2"
                style="color: black; font-size: 16px"
                >{{ item.name }}</v-card-text
              ></v-card
            ></v-row
          >
        </v-container>
      </v-card>
      <v-divider class="mb-1" />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="moveMultiDialogClose()">{{
          $t('form.actions.cancel')
        }}</v-btn>
        <v-btn color="primary" @click="moveMultiConfirm()">
          {{ $t('form.actions.ok') }}移動</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    movingItems: {
      type: Array,
      required: true,
    },
    selectedGName: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {
    moveMultiDialogClose() {
      this.$emit('moveMultiDialogClose');
    },
    moveMultiConfirm() {
      this.$emit('moveMultiConfirm');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles.scss';
.groupMenuActive {
  background-color: #e2eefc;
}
</style>
