var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticStyle:{"text-align":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"id":'btn_' + _vm.dataItem.id,"color":"primary","icon":"","disabled":_vm.stateCheck()},on:{"click":function($event){return _vm.openForge()}}},on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}])},[_c('span',[_vm._v("檢視內容")])]),_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":'btn_' + _vm.dataItem.id,"color":"primary","icon":"","disabled":_vm.stateCheck()},on:{"click":_vm.download}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v("下載")])])]}}])},[_c('v-list',{staticClass:"pa-0",attrs:{"subheader":"","flat":"","dense":""}},[_c('v-subheader',[_vm._v("選擇檔案格式")]),_c('v-divider'),_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.downloadType),callback:function ($$v) {_vm.downloadType=$$v},expression:"downloadType"}},[_c('v-list-item',[_c('v-list-item-content',{on:{"click":function($event){return _vm.iptDownload()}}},[_c('v-list-item-title',[_vm._v("IPT")])],1)],1),_c('v-list-item',[_c('v-list-item-content',{on:{"click":function($event){return _vm.stpDownload()}}},[_c('v-list-item-title',[_vm._v("STEP")])],1)],1),_c('v-list-item',[_c('v-list-item-content',{on:{"click":function($event){return _vm.dxfDownload()}}},[_c('v-list-item-title',[_vm._v("DXF")])],1)],1)],1)],1)],1),_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"id":'btn_' + _vm.dataItem.id,"color":"primary","icon":"","disabled":_vm.stateCheck()}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-cog")])],1)]}}],null,true)},[_c('span',[_vm._v("進階設定")])])]}}])},[_c('v-card',{attrs:{"flat":""}},[_c('AdvancedMenu',{attrs:{"item":_vm.dataItem}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }