<template>
  <v-card color="#F8F8F8" flat>
    <v-card-text class="pl-3 pt-2 pb-2">進階設定</v-card-text>
    <v-divider />
    <v-card class="pa-1">
      <v-list v-if="!stop" flat class="pa-0">
        <v-list-item-group>
          <v-list-item style="height: 20px">
            <v-list-item-icon class="mt-3">
              <v-icon>mdi-folder-move-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="moveItem()">
              <v-list-item-title>移至</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item style="height: 20px">
            <v-list-item-icon class="mt-3">
              <v-icon>mdi-cloud-download</v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="exportItem()">
              <v-list-item-title>下載</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item style="height: 20px" v-if="oneDriveSet">
            <v-list-item-icon class="mt-3">
              <v-icon>mdi-cloud-upload</v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="uploadToOneDrive()">
              <v-list-item-title>上傳至 OneDrive</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item style="height: 20px" v-if="share && admin">
            <v-list-item-icon class="mt-3">
              <v-icon>mdi-link-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="openInteract()">
              <v-list-item-title>開啟即時互動</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-container fluid class="pa-0 ma-0" style="height: 100%">
        <v-row class="ma-0 pa-0" align="center">
          <v-switch
            v-model="share"
            :disabled="stop"
            dense
            inset
            hide-details
            label="建立連結"
            class="pa-0 ma-0 mt-3 mb-1 mr-2 ml-4"
            :style="share ? 'font-weight: 600;' : ''"
            @click.stop="createLink()"
          ></v-switch>
          <v-progress-circular
            v-if="linkLoading"
            :size="20"
            :width="3"
            color="primary"
            indeterminate
            class="mt-2 mr-3"
          ></v-progress-circular>
          <v-tooltip bottom v-if="getLink && !stop">
            <template v-slot:activator="{ on }"
              ><v-btn
                class="mt-2"
                fab
                x-small
                elevation="0"
                v-on="on"
                color="#ECECEC"
                @click.stop="copyLink()"
                ><v-icon>mdi-content-copy</v-icon></v-btn
              ></template
            >

            <span>複製連結</span>
          </v-tooltip>
          <v-tooltip bottom v-if="getLink && !stop">
            <template v-slot:activator="{ on }"
              ><v-btn
                class="ml-2 mt-2 mr-2"
                fab
                x-small
                elevation="0"
                v-on="on"
                color="#ECECEC"
                @click.stop="openLink()"
                ><v-icon>mdi-open-in-new</v-icon></v-btn
              ></template
            >

            <span>前往</span>
          </v-tooltip></v-row
        ></v-container
      >

      <v-switch
        v-model="setPublic"
        :disabled="stop"
        dense
        inset
        hide-details
        label="權限公開"
        class="ma-3 pa-0 pb-1 ml-4 pt-1"
        :style="setPublic ? 'font-weight: 600;' : ''"
        @click.stop
      ></v-switch>

      <v-switch
        v-model="stop"
        color="error"
        dense
        inset
        hide-details
        label="停用"
        class="ma-3 pa-0 ml-4"
        :style="stop ? 'font-weight: 600;' : ''"
        @click.stop
      ></v-switch>
    </v-card>
  </v-card>
</template>

<script>
import ApiConfig from '../../../plugins/ApiConfig.js';
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    admin: {
      type: Boolean,
      default: false,
    },
    oneDriveSet: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      setPublic: this.item.isPublic,
      stop: !this.item.isEnable,
      share: false,
      linkLoading: false,
      getLink: false,
      link: this.item.link,
    };
  },
  watch: {
    item: {
      handler() {
        this.stop = !this.item.isEnable;
      },
      deep: true,
    },
    stop: {
      handler() {
        this.enableChange();
      },
      deep: true,
    },
    setPublic: {
      handler() {
        this.publicChange();
      },
      deep: true,
    },
  },
  mounted() {
    if (this.item.link !== null) {
      this.share = true;
      this.getLink = true;
    }
  },
  methods: {
    /**搬移元件至其他群組 */
    moveItem() {
      this.$emit('moveItem', this.item);
    },
    exportItem() {
      this.$emit('exportItem', this.item);
    },
    uploadToOneDrive(){
      var itemId = this.item.id;
      this.$API.api.pc.productItem
          .uploadToOneDrive(itemId)
          .then(() => {
            console.log("uploadToOneDrive")
          })
          .catch((error) => {
            console.error(error);
            this.$store.commit('notification/SET_DIALOG', {
              title: 'Error',
              message: error.response.data,
              value: true,
            });
          })
          .finally(() => {
            var msg = '上傳成功'
            this.$store.commit('notification/SET_ALERT', {
              type: 'success',
              message: msg,
              value: true,
            });
            
          });
    },
    openInteract(){
      var date = new Date();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }

      var interactLink = this.link + '?roomId=' + month + strDate + '&eventTriggered=false&restoreFPS=100&restoreImmediately&latency=200&readOnly=true&defaultOpenForm=false&model3d=true'
      window.open(interactLink);
    },
    copyLink() {
      const clipboardy = require('clipboardy');
      clipboardy.write(this.link);
      let msg = '已複製連結';
      this.$store.commit('notification/SET_ALERT', {
        type: 'success',
        message: msg,
        value: true,
      });
    },
    async enableChange() {
      var itemId = this.item.id;
      var isEnable = !this.stop;

      return new Promise((resolve) => {
        this.$API.api.pc.productItem
          .postEnable(itemId, isEnable, ApiConfig.getJsonConfig)
          .then(() => {})
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.$emit('reload');
            resolve();
          });
      });
    },
    async publicChange() {
      var itemId = this.item.id;
      var isPublic = this.setPublic;

      return new Promise((resolve) => {
        this.$API.api.pc.productItem
          .postPublic(itemId, isPublic, ApiConfig.getJsonConfig)
          .then(() => {})
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.$emit('reload');
            resolve();
          });
      });
    },
    /**建立連結 */
    createLink() {
      var itemId = this.item.id;
      if (this.share == true) {
        this.linkLoading = true;
        return new Promise((resolve) => {
          this.$API.api.pc.productItemShare
            .post(itemId, ApiConfig.getJsonConfig)
            .then((res) => {
              this.link = res.data;
              this.$emit('createLink', this.item, this.link);
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              this.linkLoading = false;
              this.getLink = true;
              resolve();
            });
        });
      } else {
        //撤銷連結
        this.linkLoading = false;
        this.getLink = false;
        return new Promise((resolve) => {
          this.$API.api.pc.productItemShare
            .delete(itemId, ApiConfig.getJsonConfig)
            .then(() => {
              this.link = '';
              this.$emit('clearLink', this.item);
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              resolve();
            });
        });
      }
    },
    openLink() {
      window.open(this.link);
    },
  },
};
</script>
