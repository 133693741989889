<template>
  <v-snackbar v-model="snackbar" color="success" timeout="10000">
    {{
      $t('snackbar.download_packing') +
      $t('snackbar.comma') +
      $t('snackbar.please_go_to')
    }}
    <span
      style="border-bottom: 1px solid white; cursor: pointer; font-weight: 660"
      @click="openFilePackage()"
    >
      {{ $t('snackbar.file_package') }}</span
    >
    {{ $t('snackbar.check') }}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="snackbar = false">
        {{ $t('general.close') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    downloadSnackbar: Boolean,
  },
  data() {
    return {
      link: '',
      snackbar: false,
      mainUrl: process.env.VUE_APP_PORTAL_BASE_URL,
    };
  },
  watch: {
    downloadSnackbar(val) {
      if (val) {
        this.snackbar = true;
        setTimeout(() => (this.snackbar = false), 10000);
      }
    },
  },
  methods: {
    openFilePackage() {
      this.link = this.mainUrl + '/user/me/Packages';
      window.open(this.link);
    },
  },
};
</script>
