<template>
  <v-card flat style="height:85vh;"
    ><ManagePage
      v-if="!forbidden"
      :companyId="companyId"
      :uploadGroup="uploadGroup"
      :openVersion="openVersion"
      :groupId="groupId"
      :itemId="itemId"
      :versionId="versionId"
      :isEnable="isEnable"
      :readOnly="readOnly()"
  /></v-card>
</template>

<script>
import ApiConfig from '../../plugins/ApiConfig.js';
import { mapState } from 'vuex';
import ManagePage from '../../components/ProductManagement/Main';
export default {
  components: {
    ManagePage,
  },
  data() {
    return {
      uploadGroup: this.$route.params.group,
      // companyId: this.$route.params.companyId,
      openVersion: this.$route.params.openVersion,
      groupId: this.$route.query.group,
      itemId: this.$route.query.item,
      versionId: this.$route.query.version,
      isEnable: this.$route.query.isEnable,
      forbidden: null,
    };
  },
  computed: {
    ...mapState('auth', ['company']),
    ...mapState('company', {
      companyId: (state) => state.company.id,
    }),
  },
  watch: {
    companyId: {
      handler(val) {
        if (val === undefined || val === null || val === '') {
          this.companyId = this.$route.params.companyId;
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.versionId !== undefined && this.itemId === undefined) {
      this.getVersionData();
    }
  },
  methods: {
    readOnly() {
      var readCheck = true;
      var functions = this.company.functions;
      for (let i = 0; i < functions.length; i++) {
        if (
          functions[i].name ==
          'Company.ProductConfigurator.ProductConfigurator.Manage'
        ) {
          readCheck = false;
        }
      }
      return readCheck;
    },
    getVersionData() {
      return new Promise((resolve) => {
        this.$API.api.pc.productVersion
          .get(this.versionId, ApiConfig.getJsonConfig)
          .then((res) => {
            this.groupId = res.data.groupId;
            this.itemId = res.data.itemId;
            history.pushState(
              {},
              null,
              this.$route.path +
                '?group=' +
                encodeURIComponent(this.groupId) +
                '&item=' +
                encodeURIComponent(this.itemId) +
                '&version=' +
                encodeURIComponent(this.versionId)
            );
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            // this.$router.push({
            //   name: 'ManagePage',
            //   params: { companyId: this.companyId },
            //   query: {
            //     group: this.groupId,
            //     item: this.itemId,
            //     version: this.versionId,
            //   },
            // });
            resolve();
          });
      });
    },
  },
};
</script>
