<template>
  <v-card style="height:100% " class="pa-0 ma-0" flat>
    <v-card-title class="pt-2 pl-2 pr-3 pb-2" style="height:60px">
      <v-btn icon class="mr-2" @click="back()"
        ><v-icon>mdi-arrow-left</v-icon></v-btn
      >
      <v-divider vertical class="mr-3" />
      {{ groupName }}
      <v-icon>mdi-chevron-right</v-icon>{{ itemName }}
      <v-icon>mdi-chevron-right</v-icon>版本管理
      <v-spacer />
      <v-chip
        v-if="publishVersionNumber !== null"
        color="primary"
        class="mr-2"
        outlined
        @click="clickNow()"
        >當前版本：{{ publishVersionNumber }}</v-chip
      >
      <v-chip
        v-if="publishVersionNumber == null"
        color="#7a7a7a"
        class="mr-2"
        outlined
        >未發布</v-chip
      >

      <v-tooltip bottom v-if="!readOnly">
        <template v-slot:activator="{ on }">
          <v-btn icon large color="primary" v-on="on" @click="uploadFileOpen()"
            ><v-icon>mdi-plus-circle</v-icon>
          </v-btn>
        </template>
        <span>新增版本</span>
      </v-tooltip>
    </v-card-title>
    <v-divider />
    <v-card :loading="loading">
      <v-container fluid class="pa-0 ma-0" style="height: 100%">
        <v-row class=" pa-0 ma-0">
          <!-- VersionList  左側：版本列表  -->
          <v-card
            flat
            class="pa-0 ma-0"
            width="380"
            :style="
              isFullScreen
                ? 'height: calc(100vh - 64px);overflow-y: scroll;'
                : 'height:calc(100vh - 202px); overflow-y: scroll;'
            "
          >
            <VersionList
              ref="versionList"
              :item="item"
              :itemIdOpen="itemIdOpen"
              :versionIdOpen="versionIdOpen"
              :publishVersionNumber="publishVersionNumber"
              @clickVersion="clickVersion"
          /></v-card>
          <v-col class="pa-0 ma-0">
            <!-- ModelList  右側：規格品列表  -->
            <v-card flat class="pa-0 ma-0" style="height:calc(100% - 68px);">
              <ModelList
                ref="modelList"
                :item="item"
                :companyId="companyId"
                :isFullScreen="isFullScreen"
                :readOnly="readOnly"
                @reload="reload"
                @loadingStart="loadingStart"
                @loadingEnd="loadingEnd"
                @versionIsAllowedChange="versionIsAllowedChange"
            /></v-card>
          </v-col>
        </v-row> </v-container
    ></v-card>
    <v-dialog
      v-model="uploadFile"
      max-width="450"
      persistent
      no-click-animation
    >
      <UploadFileDialog
        ref="uploadFile"
        @uploadFileClose="uploadFile = false"
        @uploadFileConfirm="uploadFileConfirm"
    /></v-dialog>
    <v-dialog v-model="noQuota" max-width="450">
      <NoQuotaDialog
        @noQuotaDialogClose="noQuota = false"
        @uploadFileConfirm="uploadFileConfirm"
    /></v-dialog>
  </v-card>
</template>

<script>
import ApiConfig from '../../../plugins/ApiConfig.js';
import VersionList from './VersionList';
import ModelList from './ModelList';
import UploadFileDialog from '../../Dialog/UploadFileDialog';
import NoQuotaDialog from '../../Dialog/NoQuotaDialog';
export default {
  components: {
    VersionList,
    ModelList,
    UploadFileDialog,
    NoQuotaDialog,
  },
  props: {
    companyId: {
      type: String,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    isFullScreen: {
      type: Boolean,
    },
    itemIdOpen: {
      type: String,
    },
    versionIdOpen: {
      type: String,
    },
    readOnly: {
      type: Boolean,
    },
  },
  data() {
    return {
      balance: null,
      loading: true,
      uploadFile: false,
      noQuota: false,
      groupName: null,
      itemName: null,
      publishVersionNumber: null,
    };
  },
  watch: {
    item: {
      handler(val) {
        this.publishVersionNumber = val.publishVersionNumber;
      },
      deep: true,
    },
    itemIdOpen: {
      handler(val) {
        if (val) {
          this.getPublishVersionNumber();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.loadBalance();
    if (this.versionIdOpen) {
      this.loadVersionData();
    } else {
      this.groupName = this.product.name;
      this.itemName = this.item.name;
      this.publishVersionNumber = this.item.publishVersionNumber;
    }
    if (this.itemIdOpen) {
      this.getPublishVersionNumber();
    }
  },
  methods: {
    back() {
      this.$emit('closeVersion');
    },
    /**直接打開規格品列表時，取的verison資料 */
    loadVersionData() {
      return new Promise((resolve) => {
        var versionId = this.versionIdOpen;
        this.$API.api.pc.productVersion
          .get(versionId, ApiConfig.getJsonConfig)
          .then((res) => {
            var data = res.data;
            if (data.groupName == '' || data.groupName == null) {
              this.groupName = '未分類群組';
            } else {
              this.groupName = data.groupName;
            }
            this.itemName = data.itemName;
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            resolve();
          });
      });
    },
    getPublishVersionNumber() {
      return new Promise((resolve) => {
        var itemId = this.itemIdOpen;
        this.$API.api.pc.productItem
          .get(itemId, ApiConfig.getJsonConfig)
          .then((res) => {
            var data = res.data;
            if (data.publishVersionNumber == 0) {
              this.publishVersionNumber = null;
            } else {
              this.publishVersionNumber = data.publishVersionNumber;
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            resolve();
          });
      });
    },
    clickVersion(version) {
      this.loadingStart();
      this.$refs.modelList.loadData(version);
      this.$emit('addVersionToLocation', version);
    },
    clickNow() {
      this.$refs.versionList.clickNow();
    },
    /**更新元件 */
    reload(apply) {
      this.loading = true;
      this.$emit('reloadItem', false, apply);
      // await this.$refs.versionList.loadVersions();
      // this.$refs.versionList.viewVersions(this.item);
    },
    finishReload(upload, apply) {
      this.$refs.versionList.loadVersions(upload, undefined, apply);
      this.loading = false;
    },
    /**從模型轉檔開啟版本列表 */
    openSpecVersion(openVersion) {
      this.$refs.versionList.loadVersions(false, openVersion);
    },
    uploadFileOpen() {
      if (this.balance > 0) {
        this.uploadFile = true;
      } else {
        this.noQuota = true;
      }
    },
    /**新增一個元件的 version (上傳) */
    uploadFileConfirm(file) {
      this.loading = true;
      var itemId = this.item.id;
      let formData = new FormData();
      formData.append('file', file);
      this.$API.api.pc.productItem
        .postVersion(itemId, formData, ApiConfig.getJsonConfig)
        .then(() => {})
        .catch((error) => {
          console.error(error);
          this.$store.commit('notification/SET_DIALOG', {
            title: 'Error',
            message: error,
            value: true,
          });
        })
        .finally(() => {
          this.uploadFile = false;
          this.$refs.uploadFile.finishUpload();
          var upload = true;
          this.$emit('reloadItem', upload);
          let msg = '新增版本成功';
          this.$store.commit('notification/SET_ALERT', {
            type: 'success',
            message: msg,
            value: true,
          });
        });
    },
    loadingStart() {
      this.loading = true;
    },
    loadingEnd() {
      this.loading = false;
    },
    async loadBalance() {
      var companyId = this.companyId;
      var start = null;
      var end = null;
      var res = await this.$API.api.pc.companyProcessingUsage.getBalance(
        companyId,
        start,
        end,
        ApiConfig.getJsonConfig
      );
      this.balance = res.data;
    },
    /**即時轉檔設定更改 */
    versionIsAllowedChange(versionId) {
      this.$refs.versionList.isAlllowedChanged(versionId);
    },
  },
};
</script>
