<template>
  <v-sheet height="64">
    <v-divider />
    <v-toolbar flat outlined color="#FCFCFC">
      <v-checkbox
        v-model="selectAll"
        label="全選"
        style="margin-left: 11px; margin-top:16px"
      />
      <v-spacer />
      <span style="font-size: 18px">移動至 {{ selectedGName }}</span>
      <ChooseGroupMenu :companyId="companyId" @getGroupName="getGroupName" />
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            class="actionBtn mr-3 ml-3"
            color="primary"
            :fab="$vuetify.breakpoint.name == 'xs'"
            :small="$vuetify.breakpoint.name == 'xs'"
            v-on="on"
            outlined
            @click="cancelMove()"
            ><span v-if="$vuetify.breakpoint.name != 'xs'">取消</span>
          </v-btn>
        </template>
        <span>取消</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            class="actionBtn"
            color="primary"
            :fab="$vuetify.breakpoint.name == 'xs'"
            :small="$vuetify.breakpoint.name == 'xs'"
            v-on="on"
            @click="moveItemClick()"
            ><span v-if="$vuetify.breakpoint.name != 'xs'">確定移動</span>
          </v-btn>
        </template>
        <span>確定移動</span>
      </v-tooltip>
    </v-toolbar>
  </v-sheet>
</template>

<script>
import ChooseGroupMenu from './ChooseGroupMenu';
export default {
  components: {
    ChooseGroupMenu,
  },
  props: {
    companyId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectAll: false,
      selectedGName: '未分類群組',
    };
  },
  watch: {
    selectAll: {
      handler(val) {
        if (val) {
          this.$emit('selectAll');
        } else {
          this.$emit('clearAll');
        }
      },
      deep: true,
    },
  },
  methods: {
    cancelMove() {
      this.$emit('cancelMove');
    },
    /**批次移動目標 */
    getGroupName(name, group) {
      this.selectedGName = name;
      this.$emit('getGroupName', name, group);
    },
    moveItemClick() {
      if (this.selectAll) {
        this.$emit('moveAllItem');
      } else {
        this.$emit('moveItemClick');
      }
    },
  },
};
</script>
