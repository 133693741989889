<template>
  <v-card color="#F8F8F8" flat>
    <v-card-text class="pl-3 pt-2 pb-2">進階設定</v-card-text>
    <v-divider />
    <v-card class="pa-1 pr-3 pb-3">
      <v-container fluid class="pa-0 ma-0" style="height:100%">
        <v-row class="ma-0 pa-0" align="center">
          <v-switch
            v-model="share"
            dense
            inset
            hide-details
            label="建立連結"
            class="pa-0 ma-0 mt-3 mb-1 mr-2 ml-4"
            :style="share ? 'font-weight: 600;' : ''"
            @click.stop="createLink()"
          ></v-switch>
          <v-progress-circular
            v-if="linkLoading"
            :size="20"
            :width="3"
            color="primary"
            indeterminate
            class="mt-2 mr-3"
          ></v-progress-circular>
          <v-tooltip bottom v-if="getLink">
            <template v-slot:activator="{ on }"
              ><v-btn
                class="mt-2"
                fab
                x-small
                elevation="0"
                v-on="on"
                color="#ECECEC"
                @click.stop="copyLink()"
                ><v-icon>mdi-content-copy</v-icon></v-btn
              ></template
            >

            <span>複製連結</span>
          </v-tooltip>
          <v-tooltip bottom v-if="getLink">
            <template v-slot:activator="{ on }"
              ><v-btn
                class="ml-2 mt-2"
                fab
                x-small
                elevation="0"
                v-on="on"
                color="#ECECEC"
                @click.stop="openLink()"
                ><v-icon>mdi-open-in-new</v-icon></v-btn
              ></template
            >

            <span>前往</span>
          </v-tooltip></v-row
        ></v-container
      >
    </v-card></v-card
  >
</template>

<script>
import ApiConfig from '../../plugins/ApiConfig.js';
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      share: false,
      linkLoading: false,
      getLink: false,
      link: this.item.link,
    };
  },
  watch: {},
  mounted() {
    if (this.item.link !== null) {
      this.share = true;
      this.getLink = true;
    }
  },
  methods: {
    copyLink() {
      const clipboardy = require('clipboardy');
      clipboardy.write(this.link);
      let msg = '已複製連結';
      this.$store.commit('notification/SET_ALERT', {
        type: 'success',
        message: msg,
        value: true,
      });
    },
    /**建立連結 */
    createLink() {
      var modelId = this.item.id;
      if (this.share == true) {
        this.linkLoading = true;
        return new Promise((resolve) => {
          this.$API.api.pc.productModelShare
            .post(modelId, ApiConfig.getJsonConfig)
            .then((res) => {
              this.link = res.data;
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              this.linkLoading = false;
              this.getLink = true;
              resolve();
            });
        });
      } else {
        //撤銷連結
        this.linkLoading = false;
        this.getLink = false;
        return new Promise((resolve) => {
          this.$API.api.pc.productModelShare
            .delete(modelId, ApiConfig.getJsonConfig)
            .then(() => {
              this.link = '';
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              resolve();
            });
        });
      }
    },
    openLink() {
      window.open(this.link);
    },
  },
};
</script>
