<template>
  <div>
    <v-card>
      <v-card-title
        ><v-container fluid class="ma-0 pa-0"
          ><v-row class="ma-0 pa-0"
            ><v-col class="ma-0 pa-0">移動 {{ item.name }}</v-col>
            <v-col :cols="1" class="ma-0 pa-0"
              ><v-tooltip bottom v-if="!addNewGroup">
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="primary"
                    fab
                    dark
                    x-small
                    :elevation="1"
                    v-on="on"
                    @click="addNewGroup = true"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>新增群組</span>
              </v-tooltip>
              <v-btn
                v-if="addNewGroup"
                color="primary"
                fab
                dark
                x-small
                :elevation="1"
                @click="addNewGroup = false"
              >
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn></v-col
            ></v-row
          ></v-container
        ></v-card-title
      >
      <v-divider />
      <v-card flat :loading="loading">
        <v-container
          fluid
          class="pa-0 ma-0"
          style="height:100%"
          v-if="addNewGroup"
        >
          <v-row class="ma-0 pa-0">
            <v-text-field
              v-model="groupName"
              class="ml-3 mt-3 mb-3"
              label="新增群組"
              outlined
              dense
              hide-details
              @click.stop
            ></v-text-field>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }"
                ><v-btn
                  class="ml-1 mt-3 mr-2"
                  color="primary"
                  icon
                  tile
                  v-on="on"
                  @click.stop="addGroup()"
                  ><v-icon>mdi-check</v-icon></v-btn
                ></template
              >

              <span>確定新增</span>
            </v-tooltip></v-row
          ><v-divider /></v-container
      ></v-card>

      <v-list flat class="pa-0">
        <v-card
          :loading="groups == null ? true : false"
          flat
          max-height="400"
          min-width="140"
          style="overflow-y: scroll;"
        >
          <v-list-item-group
            v-model="selectedGroup"
            color="primary"
            active-class="groupMenuActive"
          >
            <template v-for="item in myGroups">
              <v-list-item :key="item.id">
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-item-group></v-card
        >
      </v-list>
      <v-divider class="mb-1" />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="moveDialogClose()">{{
          $t('form.actions.cancel')
        }}</v-btn>
        <v-btn
          color="primary"
          @click="moveItemConfirm()"
          :disabled="selectedGroup == undefined"
          :dark="selectedGroup !== undefined"
        >
          {{ $t('form.actions.ok') }}移動</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import ApiConfig from '../../plugins/ApiConfig';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
export default {
  props: {
    companyId: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      groups: null,
      groupName: '',
      selectedGroup: 0,
      myGroups: [{ name: '未分類群組', id: null }],
      dataState: {
        page: 0,
        sort: [{ field: 'name', dir: 'asc' }],
      },
      addNewGroup: false,
      loading: false,
    };
  },
  mounted() {
    this.loadGroups();
  },
  methods: {
    /**讀取所有群組 */
    async loadGroups() {
      var companyId = this.companyId;
      var dataState = this.dataState;
      const queryStr = toDataSourceRequestString(dataState);
      var urlencoded = new URLSearchParams(queryStr);
      var res = await this.$API.api.pc.companyProductGroup.get(
        companyId,
        null,
        urlencoded,
        ApiConfig.getJsonConfig
      );
      var data = res.data.data;
      this.groups = data;
      for (let i = 0; i < this.groups.length; i++) {
        this.myGroups.push(this.groups[i]);
      }
      if (this.loading) {
        this.pickNewGroup();
      }
    },
    /**確定新增 */
    addGroup() {
      if (this.groupName.trim() !== '' && this.groupName !== undefined) {
        this.loading = true;
        return new Promise((resolve) => {
          this.$API.api.pc.companyProductGroup
            .post(this.companyId, this.groupName, ApiConfig.getJsonConfig)
            .then(() => {})
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              this.myGroups = [{ name: '未分類群組', id: null }];
              this.loadGroups();
              resolve();
            });
        });
      }
    },
    pickNewGroup() {
      for (let i = 0; i < this.myGroups.length; i++) {
        if (this.groupName == this.myGroups[i].name) {
          this.selectedGroup = i;
        }
      }
      this.loading = false;
      this.addNewGroup = false;
      this.groupName = '';
    },
    moveDialogClose() {
      this.groupName = '';
      this.addNewGroup = false;
      this.selectedGroup = 0;
      this.$emit('moveDialogClose');
    },
    moveItemConfirm() {
      var group = this.myGroups[this.selectedGroup];
      this.$emit('moveItemConfirm', group);
      this.addNewGroup = false;
      this.groupName = '';
      this.selectedGroup = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles.scss';
.groupMenuActive {
  background-color: #e2eefc;
}
</style>
