<template>
  <v-card flat color="transparent">
    <v-menu offset-y top left>
      <template v-slot:activator="{ on }">
        <v-btn small icon v-on="on" class="ml-1">
          <v-icon>mdi-menu-up</v-icon>
        </v-btn>
      </template>

      <v-list subheader flat class="pa-0" dense>
        <v-subheader>選擇群組</v-subheader>
        <v-divider />
        <v-card max-height="300" min-width="140" style="overflow-y: scroll;">
          <v-list-item-group
            v-model="selectedGroup"
            color="primary"
            active-class="groupMenuActive"
          >
            <template v-for="item in myGroups">
              <v-list-item :key="item.id">
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-item-group></v-card
        >
      </v-list>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-fab-transition>
            <v-btn
              color="primary"
              class="mb-5 mr-1"
              fab
              dark
              x-small
              absolute
              bottom
              right
              :elevation="1"
              v-on="on"
              @click="addGroup()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
        <span>新增群組</span>
      </v-tooltip>
    </v-menu>
    <v-dialog v-model="addDialog" persistent no-click-animation max-width="400">
      <AddGroupDialog
        @addGroupConfirm="addGroupConfirm"
        @addDialogClose="addDialogClose"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import ApiConfig from '../../../plugins/ApiConfig';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import AddGroupDialog from '../../Dialog/AddGroupDialog';
export default {
  components: {
    AddGroupDialog,
  },
  props: {
    companyId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      groups: [],
      myGroups: [{ name: '未分類群組', id: null }],
      selectedGroup: 0,
      selectedGName: '未分類群組',
      selected: {},
      dataState: {
        page: 0,
        sort: [{ field: 'name', dir: 'asc' }],
      },
      addDialog: false,
      addNew: false,
      addNewName: '',
      addDisabled: false,
    };
  },
  watch: {
    selectedGroup: {
      handler() {
        this.getGroupName();
      },
      deep: true,
    },
  },
  created() {
    this.loadGroups();
  },
  mounted() {
    // this.loadGroups();
  },
  methods: {
    /**讀取所有群組 */
    async loadGroups() {
      var companyId = this.companyId;
      var dataState = this.dataState;
      const queryStr = toDataSourceRequestString(dataState);
      var urlencoded = new URLSearchParams(queryStr);
      var res = await this.$API.api.pc.companyProductGroup.get(
        companyId,
        null,
        urlencoded,
        ApiConfig.getJsonConfig
      );
      var data = res.data.data;
      this.groups = data;
      for (let i = 0; i < this.groups.length; i++) {
        this.myGroups.push(this.groups[i]);
      }

      this.setSelected();
      this.getGroupName();
    },
    setSelected() {
      for (let i = 0; i < this.groups.length; i++) {
        if (this.addNew) {
          // console.log("addNew", this.addNewName);
          if (this.addNewName == this.groups[i].name) {
            this.selectedGroup = i + 1;
          }
        } else if (this.group !== undefined) {
          if (this.group.id == this.groups[i].id) {
            this.selectedGroup = i + 1;
          }
        }
      }
    },
    getGroupName() {
      this.selectedGName = this.myGroups[this.selectedGroup].name;
      this.selected = this.myGroups[this.selectedGroup];
      this.$emit('getGroupName', this.selectedGName, this.selected);
    },
    /**新增群組 */
    addGroup() {
      this.addDialog = true;
    },
    addGroupConfirm(groupName) {
      this.addNew = true;
      this.addNewName = groupName;
      this.addDialog = false;
      return new Promise((resolve) => {
        this.$API.api.pc.companyProductGroup
          .post(this.companyId, groupName, ApiConfig.getJsonConfig)
          .then(() => {})
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.myGroups = [{ name: '未分類群組', id: null }];
            this.loadGroups();
            resolve();
          });
      });
    },
    addDialogClose() {
      this.addDialog = false;
    },
  },
};
</script>
