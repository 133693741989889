<template
  ><sb-ui-fullScreen
    ref="full-screen"
    :z-index="200"
    height="100vh"
    @toggle="onToggle"
  >
    <v-card
      v-if="!forbidden"
      outlined
      :style="isFullScreen ? 'height: 100vh;' : 'height: 85vh;'"
    >
      <v-card-title>
        <v-icon class="pr-3"> mdi-format-list-bulleted-square</v-icon>模型列表
        <v-spacer />
        <v-text-field
          v-model="search.text"
          class="table-search ma-0 pa-0"
          append-icon="mdi-magnify"
          :label="$t('form.general.search')"
          single-line
          hide-details
          clearable
          :disabled="searchDisabled"
          @keyup.13="searchEvent"
          @click:append="searchEvent"
        ></v-text-field>
        <div>
          <Tool
            :companyId="companyId"
            :readOnly="readOnly"
            @addGroup="addGroup"
            @sortGroup="sortGroup"
          />
        </div>
        <v-divider class="mx-3" vertical />
        <v-tooltip bottom>
          <template #activator="{on}">
            <v-btn v-on="on" icon @click="toggleFullscreen">
              <v-icon v-if="!isFullScreen">mdi-fullscreen</v-icon>
              <v-icon v-else>mdi-fullscreen-exit</v-icon>
            </v-btn>
          </template>
          <span>{{ isFullScreen ? '退出全螢幕' : '全螢幕' }}</span>
        </v-tooltip></v-card-title
      >
      <v-card-text
        class="pa-2"
        style="height:calc(100% - 68px); background-color:#F3F3F3; "
      >
        <Product
          ref="product"
          :companyId="companyId"
          :uploadGroup="uploadGroup"
          :openVersion="openVersion"
          :isFullScreen="isFullScreen"
          :groupIdOpen="groupId"
          :itemIdOpen="itemId"
          :versionIdOpen="versionId"
          :isEnable="isEnable"
          :readOnly="readOnly"
          @forbiddenOpen="forbiddenOpen"
          @searchDisabledChange="searchDisabledChange"
        />
      </v-card-text>
      <v-dialog
        v-model="addDialog"
        persistent
        no-click-animation
        max-width="400"
      >
        <AddGroupDialog
          @addGroupConfirm="addGroupConfirm"
          @addDialogClose="addDialogClose"
        />
      </v-dialog> </v-card
    ><v-card flat color="transparent" v-if="forbidden"><Forbidden403 /></v-card>
  </sb-ui-fullScreen>
</template>

<script>
import ApiConfig from '../../plugins/ApiConfig.js';
import Tool from './Tool';
import Product from './ProductList';
import AddGroupDialog from '../Dialog/AddGroupDialog';
import Forbidden403 from '../SystemPage/Forbidden403';
export default {
  components: {
    Tool,
    Product,
    AddGroupDialog,
    Forbidden403,
  },
  props: {
    companyId: {
      type: String,
    },
    uploadGroup: {
      type: Object,
    },
    openVersion: {
      type: Object,
    },
    groupId: {
      type: String,
    },
    itemId: {
      type: String,
    },
    versionId: {
      type: String,
    },
    isEnable: {
      type: String,
    },
    readOnly: {
      type: Boolean,
    },
  },
  data() {
    return {
      isFullScreen: false,
      search: {
        text: '',
      },
      addDialog: false,
      forbidden: false,
      searchDisabled: true,
    };
  },
  methods: {
    /**新增群組 */
    addGroup() {
      this.addDialog = true;
    },
    addGroupConfirm(groupName) {
      this.addDialog = false;
      return new Promise((resolve) => {
        this.$API.api.pc.companyProductGroup
          .post(this.companyId, groupName, ApiConfig.getJsonConfig)
          .then(() => {
            this.$refs.product.reloadGroup();
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            resolve();
          });
      });
    },
    addDialogClose() {
      this.addDialog = false;
    },
    /**排序 */
    sortGroup() {
      this.$refs.product.sortGroup();
    },
    /**批次上傳 */
    resultUpload() {
      // this.$emit("resultUpload")
      //var formId = this.$parent.formId;
      var companyId = this.$route.params.companyId;
      this.$router.push({
        name: 'FormResultUpload',
        params: {
          companyId: companyId,
        },
      });
    },
    /**搜尋 */
    searchEvent() {
      this.$refs.product.search(this.search.text);
    },
    onToggle(value) {
      this.isFullScreen = value;
    },
    toggleFullscreen() {
      this.$refs['full-screen'].toggle();
    },
    forbiddenOpen() {
      this.forbidden = true;
    },
    searchDisabledChange(disabled) {
      this.searchDisabled = disabled;
    },
  },
};
</script>
