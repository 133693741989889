var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","color":"transparent"}},[_vm._l((_vm.myItems),function(item){return _c('v-card',{key:item.id,staticClass:"ma-2 pa-2",attrs:{"elevation":1,"color":item.active ? '#E2EEFC' : ''}},[_c('v-container',{staticClass:"pa-0 ma-0",staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"align":"center"}},[_c('v-card',{staticClass:"pa-1",attrs:{"outlined":"","width":"110","height":"110"},on:{"click":function($event){return _vm.viewVersions(item)}}},[(item.image == undefined || item.image == null)?_c('v-skeleton-loader',{attrs:{"width":"100","max-height":"100","type":"image"}}):_vm._e(),(
              item.image !== undefined &&
              item.image !== null &&
              item.image !== 'notFound'
            )?_c('v-img',{attrs:{"lazy-src":item.image,"src":item.image,"contain":"","max-height":"100","max-width":"100"}}):_vm._e(),(item.image == 'notFound')?_c('v-img',{staticStyle:{"background-color":"#f0f0f0","opacity":"0.45"},attrs:{"lazy-src":_vm.noImage,"src":_vm.noImage,"contain":"","height":"100","width":"100"}}):_vm._e()],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":_vm.readOnly ? 8 : 5}},[_c('v-card',{staticClass:"pt-2",attrs:{"flat":"","color":"transparent","height":"100"},on:{"click":function($event){return _vm.viewVersions(item)}}},[_c('v-card-text',{class:item.active
                  ? 'pt-0 pb-1 primary--text'
                  : 'pt-0 pb-1 black--text',staticStyle:{"font-weight":"600","font-size":"18px","margin-top":"6px"}},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(item.name))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [(!item.isPublic)?_c('v-icon',_vm._g({staticStyle:{"margin-top":"-3px","margin-left":"1px","margin-right":"8px"},attrs:{"small":""}},on),[_vm._v("mdi-lock")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("權限不公開")])]),(item.publishVersionId !== null)?_c('v-btn',{staticStyle:{"margin-top":"0px"},attrs:{"outlined":"","color":"primary","disabled":"","width":"56","small":"","id":"custom-disabled"}},[_vm._v("版本 "+_vm._s(item.publishVersionNumber)+" ")]):_vm._e(),(item.publishVersionNumber == null)?_c('v-btn',{staticStyle:{"margin-top":"0px"},attrs:{"outlined":"","color":"primary","disabled":"","width":"56","small":"","id":"custom-disabledPublished"}},[_vm._v("未發布 ")]):_vm._e()],1),_c('v-card-text',{staticClass:"pb-0 pt-2"},[_vm._v("查閱次數："),(item.viewCounts !== undefined)?_c('v-chip',{staticStyle:{"font-weight":"600"},attrs:{"small":""}},[_vm._v(" "+_vm._s(item.viewCounts)+" ")]):_vm._e(),(item.viewCounts == undefined)?_c('v-progress-circular',{staticClass:"mt-1 mr-3",attrs:{"size":18,"width":3,"color":"#B1B1B1","indeterminate":""}}):_vm._e()],1)],1)],1),(!_vm.readOnly)?_c('v-divider',{attrs:{"vertical":""}}):_vm._e(),(!_vm.readOnly)?_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"1","align":"center"}},[(item.isProcessing)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-progress-circular',_vm._g({attrs:{"size":25,"width":4,"rotate":-90,"value":_vm.value,"color":_vm.loadingColor}},on))]}}],null,true)},[_c('span',[_vm._v("處理中")])]):_vm._e()],1):_vm._e(),_c('v-col',{staticClass:"ma-0 ml-3 pa-0",attrs:{"cols":_vm.readOnly ? '' : '2',"align":_vm.readOnly ? 'end' : 'center'}},[_c('v-card',{attrs:{"flat":"","color":"transparent"}},[_c('v-btn',{staticClass:"pa-1",attrs:{"text":"","color":!item.isEnable ? '' : 'primary',"disabled":item.link == null},on:{"click":function($event){return _vm.getLink(item)}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-link")]),_vm._v("取得分享碼")],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":item.active
                      ? 'custom-openLinkDisabledActive'
                      : 'custom-openLinkDisabled',"fab":"","x-small":"","elevation":"0","color":item.active ? '#E2EEFC' : 'white',"disabled":item.link == null},on:{"click":function($event){$event.stopPropagation();return _vm.openLink(item)}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',[_vm._v("前往")])])],1)],1),(!_vm.readOnly)?_c('v-col',{staticClass:"pa-0",attrs:{"align":"right"}},[_c('v-card',{attrs:{"flat":"","color":"transparent"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"large":"","icon":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("刪除")])]),_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var menu = ref.on;
                      var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"large":"","icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-cog")])],1)]}}],null,true)},[_c('span',[_vm._v("進階設定")])])]}}],null,true)},[_c('v-card',{attrs:{"flat":""}},[_c('AdvancedMenu',{attrs:{"item":item,"admin":_vm.admin,"oneDriveSet":_vm.oneDriveSet},on:{"moveItem":_vm.moveItem,"reload":_vm.enableReload,"createLink":_vm.createLink,"clearLink":_vm.clearLink,"exportItem":_vm.exportItem}})],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"large":"","icon":""},on:{"click":function($event){return _vm.viewVersions(item)}}},on),[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]}}],null,true)},[_c('span',[_vm._v("查閱版本")])])],1)],1):_vm._e()],1)],1)],1)}),(!_vm.loading && _vm.myItems.length == 0)?_c('v-card',{attrs:{"flat":"","color":"transparent"}},[_c('EmptyCard')],1):_vm._e(),(_vm.loading)?_c('div',{staticClass:"text-center pa-2"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.deleteDialog.value),callback:function ($$v) {_vm.$set(_vm.deleteDialog, "value", $$v)},expression:"deleteDialog.value"}},[_c('DeleteDialog',{attrs:{"deleteItem":_vm.deleteDialog.deleteItem},on:{"delDialogClose":function($event){_vm.deleteDialog.value = false},"deleteItemConfirm":_vm.deleteItemConfirm}})],1),_c('DownloadSnackbar',{attrs:{"downloadSnackbar":_vm.downloadSnackbar}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }