<template>
  <div>
    <v-card>
      <v-card-title>暫無檔案 </v-card-title>
      <v-card-text
        >此規格品目前暫無 {{ fileType }} 檔，請與系統管理員聯繫。
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="modelFileNullDialogClose()">關閉</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    fileType: {
      type: String,
    },
  },
  methods: {
    modelFileNullDialogClose() {
      this.$emit('modelFileNullDialogClose');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles.scss';
</style>
