<template>
  <v-card flat color="transparent">
    <!-- 待提交 0：提交按鈕 -->
    <v-tooltip bottom v-if="version.status == 0 && version.isPreprocessing">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          fab
          small
          elevation="0"
          class="ml-3"
          height="34"
          width="34"
          color="#D1E7FF"
          @click="pendingDialogOpen()"
          ><v-icon color="primary">mdi-play</v-icon></v-btn
        >
      </template>
      <span>提交</span>
    </v-tooltip>
    <!-- 待處理 6：暫停按鈕 -->
    <v-tooltip bottom v-if="version.status == 6">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          fab
          small
          elevation="0"
          class="ml-3"
          height="34"
          width="34"
          :disabled="statusChangeLoading"
          @click="pauseDialogOpen()"
          ><v-icon color="#7D7D7D">mdi-pause</v-icon></v-btn
        >
      </template>
      <span>暫停</span>
    </v-tooltip>
    <!-- 已暫停 2：繼續按鈕 -->
    <v-tooltip bottom v-if="version.status == 2">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          fab
          small
          elevation="0"
          class="ml-3"
          height="34"
          width="34"
          color="#D1E7FF"
          :disabled="statusChangeLoading"
          @click="continueDialogOpen()"
          ><v-icon color="primary">mdi-play</v-icon></v-btn
        >
      </template>
      <span>繼續</span>
    </v-tooltip>
    <!-- 處理失敗 3：重試按鈕 -->
    <v-tooltip bottom v-if="version.status == 3">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          fab
          small
          elevation="0"
          class="ml-3"
          height="34"
          width="34"
          color="primary"
          :disabled="statusChangeLoading"
          @click="retryDialogOpen()"
          ><v-icon>mdi-replay</v-icon></v-btn
        >
      </template>
      <span>重試</span>
    </v-tooltip>
    <!-- 作廢 4：啟用按鈕 -->
    <v-tooltip bottom v-if="version.status == 4">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          fab
          small
          elevation="0"
          class="ml-3"
          height="34"
          width="34"
          color="primary"
          :disabled="statusChangeLoading"
          @click="enableDialogOpen()"
          ><v-icon>mdi-keyboard-return</v-icon></v-btn
        >
      </template>
      <span>啟用</span>
    </v-tooltip>
    <!-- 除作廢外的狀態：作廢按鈕 -->
    <v-tooltip bottom v-if="version.status !== 4 && version.status !== 5">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          fab
          small
          elevation="0"
          color="#FDECEC"
          class="ml-3"
          height="34"
          width="34"
          :disabled="statusChangeLoading"
          @click="voidDialogOpen()"
          ><v-icon color="error">mdi-delete-outline</v-icon></v-btn
        >
      </template>
      <span>作廢</span>
    </v-tooltip>
  </v-card>
  <!-- </v-card> -->
</template>

<script>
export default {
  props: {
    version: {
      type: Object,
    },
    statusChangeLoading: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  methods: {
    pendingDialogOpen() {
      this.$emit('pendingDialogOpen');
    },
    pauseDialogOpen() {
      this.$emit('pauseDialogOpen');
    },
    continueDialogOpen() {
      this.$emit('continueDialogOpen');
    },
    retryDialogOpen() {
      this.$emit('retryDialogOpen');
    },
    enableDialogOpen() {
      this.$emit('enableDialogOpen');
    },
    voidDialogOpen() {
      console.log('void click');
      this.$emit('voidDialogOpen');
    },
  },
};
</script>
