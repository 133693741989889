<template>
  <v-card flat style="height:calc(100% - 68px);">
    <v-card-title class="pa-0 pl-1 ma-2" style="height: 36px"
      >版本{{ version.versionNumber }}－規格品列表
      <v-card class="ml-2 pa-0" flat v-if="statusStr !== ''">
        <v-container fluid class="pa-0 ma-0"
          ><v-row class="pa-0 ma-0" align="center">
            <v-btn
              id="custom-disabled"
              height="34"
              width="70"
              outlined
              disabled
              style="margin-left: 2px"
              :loading="statusChangeLoading"
              >{{ statusStr }}
            </v-btn>

            <v-card v-if="!readOnly" flat>
              <v-container fluid class="pa-0 ma-0"
                ><v-row class="pa-0 ma-0" align="center"
                  ><StatusActionMenu
                    :version="version"
                    :statusChangeLoading="statusChangeLoading"
                    @pendingDialogOpen="pendingDialogOpen"
                    @pauseDialogOpen="pauseDialogOpen"
                    @continueDialogOpen="continueDialogOpen"
                    @retryDialogOpen="retryDialogOpen"
                    @enableDialogOpen="enableDialogOpen"
                    @voidDialogOpen="voidDialogOpen"/></v-row
              ></v-container> </v-card></v-row
        ></v-container>
      </v-card>
      <v-spacer />
      <v-tooltip bottom v-if="!readOnly && version.isPreprocessing">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-switch
              v-model="allowedCreateOnDemand"
              dense
              inset
              hide-details
              label="即時轉檔"
              class="pa-0 ma-0 mt-1 mb-1 mr-5"
              :style="allowedCreateOnDemand ? 'font-weight: 600;' : ''"
              @click="isAllowedChange()"
            ></v-switch></div
        ></template>
        <span>開放即時轉檔</span>
      </v-tooltip>
      <v-tooltip bottom v-if="!readOnly">
        <template v-slot:activator="{ on }">
          <v-btn
            color="primary"
            class="mr-1"
            v-on="on"
            depressed
            :disabled="version.apply"
            @click="applyVersion()"
            >應用</v-btn
          >
        </template>
        <span>應用此版本</span>
      </v-tooltip> </v-card-title
    ><v-divider />
    <localization-provider :language="$i18n.locale">
      <intl-provider :locale="$i18n.locale">
        <grid
          id="grid"
          ref="grid"
          :data-items="gridResults.gridData"
          :skip="gridResults.dataState.skip"
          :take="gridResults.dataState.take"
          :filter="gridResults.dataState.filter"
          :sort="gridResults.dataState.sort"
          :columns="columns"
          scrollable
          sortable
          reorderable
          column-menu
          pageable
          @datastatechange="dataStateChange"
          @iptDownload="iptDownload"
          @dxfDownload="dxfDownload"
          @stpDownload="stpDownload"
          @openForge="openForge"
          :style="size"
        >
          <!-- :style="setKendoheight" -->
          <grid-no-records
            :style="{ minHeight: gridResults.loading ? '256px' : '128px' }"
          >
            <div v-if="gridResults.loading" class="k-loading-mask">
              <span class="k-loading-text"></span>
              <div class="k-loading-image" />
              <div class="k-loading-color" />
            </div>
            <div v-else>{{ $t('form.no_records') }}</div>
          </grid-no-records>
        </grid>
      </intl-provider>
    </localization-provider>
    <v-dialog v-model="pendingDialog.value" max-width="550"
      ><PendingDialog
        :balance="balance"
        :pendingAmount="pendingAmount"
        :pendingItem="pendingDialog.pendingItem"
        @pendingDialogClose="pendingDialog.value = false"
        @pendingConfirm="pendingConfirm"
    /></v-dialog>
    <v-dialog v-model="pauseDialog.value" max-width="550"
      ><PauseDialog
        :pauseItem="pauseDialog.pauseItem"
        @pauseDialogClose="pauseDialog.value = false"
        @pauseConfirm="pauseConfirm"
    /></v-dialog>
    <v-dialog v-model="continueDialog.value" max-width="550"
      ><ContinueDialog
        :companyId="companyId"
        :continueItem="continueDialog.continueItem"
        @continueDialogClose="continueDialog.value = false"
        @continueConfirm="continueConfirm"
    /></v-dialog>
    <v-dialog v-model="retryDialog.value" max-width="550"
      ><RetryDialog
        :companyId="companyId"
        :retryItem="retryDialog.retryItem"
        @retryDialogClose="retryDialog.value = false"
        @retryConfirm="retryConfirm"
    /></v-dialog>
    <v-dialog v-model="enableDialog.value" max-width="550"
      ><EnableDialog
        :enableItem="enableDialog.enableItem"
        @enableDialogClose="enableDialog.value = false"
        @enableConfirm="enableConfirm"
    /></v-dialog>
    <v-dialog v-model="voidDialog.value" max-width="550"
      ><VoidDialog
        :voidItem="voidDialog.voidItem"
        @voidDialogClose="voidDialog.value = false"
        @voidConfirm="voidConfirm"
    /></v-dialog>
    <v-dialog v-model="forgeVuerDialog"
      ><v-btn
        class="mt-8"
        color="primary"
        style="z-index: 50"
        fab
        dark
        absolute
        top
        right
        @click="forgeVuerDialog = false"
      >
        <v-icon>mdi-close</v-icon> </v-btn
      ><ForgeVuerDialog :model="model"
    /></v-dialog>
    <v-dialog v-model="modelFileNullDialog" max-width="400">
      <ModelFileNullDialog
        :fileType="fileType"
        @modelFileNullDialogClose="modelFileNullDialog = false"
    /></v-dialog>
    <LoadingDialog v-model="loadingDialog" />
    <v-dialog v-model="inheritShareCodeDialog" max-width="400"
      ><InheritShareCodeDialog
        @inheritDialogComfirm="inheritDialogComfirm"
        @inheritDialogClose="inheritDialogClose"
    /></v-dialog>
  </v-card>
</template>

<script>
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import ApiConfig from '../../../plugins/ApiConfig';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import HandleStateCell from '../../GridCell/HandleStateCell.vue';
import CheckDownloadCell from '../../GridCell/CheckDownloadCell.vue';
import CheckDownloadLinkCell from '../../GridCell/CheckDownloadLinkCell';
import ModelNameDisplayCell from '../../GridCell/ModelNameDisplayCell';
// import LinkActionCell from "../../GridCell/LinkActionCell";
import StatusActionMenu from './StatusActionMenu';
import PendingDialog from '../../Dialog/PendingDialog';
import PauseDialog from '../../Dialog/PauseDialog';
import ContinueDialog from '../../Dialog/ContinueDialog';
import RetryDialog from '../../Dialog/RetryDialog';
import EnableDialog from '../../Dialog/EnableDialog';
import VoidDialog from '../../Dialog/VoidDialog';
import ForgeVuerDialog from '../../Dialog/ForgeVuerDialog';
import ModelFileNullDialog from '../../Dialog/ModelFileNullDialog';
import LoadingDialog from '../../Dialog/LoadingDialog.vue';
import InheritShareCodeDialog from '../../Dialog/InheritShareCodeDialog.vue';

export default {
  components: {
    StatusActionMenu,
    PendingDialog,
    PauseDialog,
    ContinueDialog,
    RetryDialog,
    EnableDialog,
    VoidDialog,
    ForgeVuerDialog,
    ModelFileNullDialog,
    LoadingDialog,
    InheritShareCodeDialog,
  },
  props: {
    companyId: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    isFullScreen: {
      type: Boolean,
    },
    readOnly: {
      type: Boolean,
    },
  },
  data() {
    return {
      allowedCreateOnDemand: true,
      size: '',
      balance: null,
      version: {},
      statusStr: '',
      nowVersion: { version: '', createUser: '', createDate: '' },
      myColumns: [
        {
          field: 'status',
          title: '狀態',
          width: '120px',
          minResizableWidth: 100,
          cell: HandleStateCell,
          attributes: {
            class: 'table-cell',
            style: 'text-align: center; font-size: 14px',
          },
        },
        { field: 'name', title: '型號', cell: ModelNameDisplayCell },
        { field: 'viewCount', title: '查閱次數', width: '100px' },
        // {
        //   field: "link",
        //   title: "分享連結",
        //   width: "200px",
        //   minResizableWidth: 200,
        //   cell: LinkActionCell,
        // },
      ],
      //預處理model(規格品)列表
      modelList: [],
      gridResults: {
        loading: true,
        list: [],
        total: 0,
        updatedData: [],
        gridData: {
          data: [],
          total: 0,
        },
        dataState: {
          page: 1,
          take: 20,
          skip: 0,
          sort: [{ field: 'name', dir: 'asc' }],
          filter: null,
          group: '',
        },
        pageable: {
          buttonCount: 5,
          info: true,
          type: 'numeric',
          pageSizes: [10, 20, 50],
          previousNext: true,
        },
      },
      pendingDialog: {
        value: false,
        pendingItem: {},
      },
      pauseDialog: {
        value: false,
        pauseItem: {},
      },
      continueDialog: {
        value: false,
        continueItem: {},
      },
      retryDialog: {
        value: false,
        retryItem: {},
      },
      enableDialog: {
        value: false,
        enableItem: {},
      },
      voidDialog: {
        value: false,
        voidItem: {},
      },
      pendingAmount: null,
      model: {},
      forgeVuerDialog: false,
      modelFileNullDialog: false,
      fileType: null,
      loadingDialog: false,
      statusChangeLoading: false,
      inheritShareCodeDialog: false,
    };
  },
  created() {
    window.addEventListener('resize', this.getKendoSize);
  },
  destroyed() {
    window.removeEventListener('resize', this.getKendoSize);
  },
  computed: {
    columns() {
      if (this.readOnly) {
        return [
          ...this.myColumns,
          //其他動作欄位：檢視表單及參數、下載
          {
            width: '200px',
            minResizableWidth: 200,
            cell: CheckDownloadLinkCell,
          },
        ];
      } else {
        return [
          ...this.myColumns,
          //其他動作欄位：檢視表單及參數、下載
          {
            width: '200px',
            minResizableWidth: 200,
            cell: CheckDownloadCell,
          },
        ];
      }
    },
  },
  watch: {
    item: {
      handler(val) {
        console.log('item', val);
      },
      deep: true,
    },
  },
  mounted() {
    this.getKendoSize();
    this.loadBalance();
  },
  methods: {
    getStatus() {
      if (this.version.status == 0 && !this.version.isPreprocessing) {
        this.statusStr = '待觸發';
      } else if (this.version.status == 0) {
        this.statusStr = '待提交';
      } else if (this.version.status == 1) {
        this.statusStr = '處理中';
      } else if (this.version.status == 2) {
        this.statusStr = '已暫停';
      } else if (this.version.status == 3) {
        this.statusStr = '處理失敗';
      } else if (this.version.status == 4) {
        this.statusStr = '作廢';
      } else if (this.version.status == 5) {
        this.statusStr = '已完成';
      } else if (this.version.status == 6) {
        this.statusStr = '待處理';
      }
    },
    getKendoSize() {
      var height = document.getElementById('overlay').clientHeight - 108;
      if (this.isFullScreen) {
        height = document.getElementById('overlay').clientHeight - 115;
      }
      this.size = `height:${height}px;`;
    },
    async loadData(version) {
      this.gridResults.loading = true;
      // this.$emit("loadingStart");
      // console.log('version', version);
      this.version = version;
      this.allowedCreateOnDemand = version.isAllowedCreateOnDemand;
      this.getStatus();

      var versionId = version.id;
      var dataState = this.gridResults.dataState;
      const queryStr = toDataSourceRequestString(dataState);
      var urlencoded = new URLSearchParams(queryStr);
      var res = await this.$API.api.pc.productVersionModel.get(
        versionId,
        null,
        urlencoded,
        ApiConfig.getJsonConfig
      );

      this.modelList = res.data;
      // console.log("data", this.modelList.data);
      // this.statusChange();
      this.loadModels();
    },
    /**轉換status由 0 5 變文字 */
    // statusChange() {
    //   for (let i = 0; i < this.modelList.data.length; i++) {
    //     console.log("what???", this.modelList.data[i].status);
    //     if (this.modelList.data[i].status == 5) {
    //       this.modelList.data[i].status = "已完成";
    //     } else {
    //       this.modelList.data[i].status = "未處理";
    //     }
    //   }
    //   console.log("statusChange data", this.modelList.data);
    // },
    /**預處理規格品列表 */
    loadModels() {
      this.$data.gridResults.total = this.modelList.total;
      this.$data.gridResults.list = this.modelList.data;
      this.processModelData();
      // console.log(this.modelList.data);
      this.$emit('loadingEnd');
    },
    processModelData() {
      this.gridResults.gridData.data = this.gridResults.list;
      this.gridResults.gridData.total = this.gridResults.total;
      this.gridResults.loading = false;
    },
    dataStateChange(event) {
      var dataState = event.data;
      this.gridResults.dataState.take = dataState.take;
      this.gridResults.dataState.skip = dataState.skip;
      this.gridResults.dataState.sort = dataState.sort;

      // //狀態欄位特別處理
      // if (event.event.field == "status") {
      //   var str = dataState.filter.filters[0].filters[0].value.trim();
      //   if (
      //     str.indexOf("已") != -1 ||
      //     str.indexOf("完") != -1 ||
      //     str.indexOf("成") != -1
      //   ) {
      //     dataState.filter.filters[0].filters[0].operator = "eq";
      //     dataState.filter.filters[0].filters[0].value = 5;
      //   } else if (
      //     str.indexOf("未") != -1 ||
      //     str.indexOf("處") != -1 ||
      //     str.indexOf("理") != -1
      //   ) {
      //     dataState.filter.filters[0].filters[0].operator = "neq";
      //     dataState.filter.filters[0].filters[0].value = 5;
      //   }
      // }

      this.gridResults.dataState.filter = dataState.filter;
      this.loadData(this.version);
    },
    /**應用此版本 */
    applyVersion() {
      console.log('applyVersion item', this.item);
      if (this.item.shareCode == null) {
        this.applyVersionComfirm();
      } else {
        this.inheritShareCodeDialog = true;
      }
    },
    applyVersionComfirm(keepShareCode) {
      if (keepShareCode) {
        var doesKeepShareCode = true;
      } else {
        doesKeepShareCode = false;
      }
      var itemId = this.item.id;
      var versionId = this.version.id;
      var apply = true;
      return new Promise((resolve) => {
        this.$API.api.pc.productItemVersion
          .publish(
            itemId,
            versionId,
            doesKeepShareCode,
            ApiConfig.getJsonConfig
          )
          .then(() => {})
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.$emit('reload', apply);
            resolve();
          });
      });
    },
    inheritDialogClose() {
      this.applyVersionComfirm();
      this.inheritShareCodeDialog = false;
    },
    inheritDialogComfirm() {
      this.applyVersionComfirm(true);
      this.inheritShareCodeDialog = false;
    },
    iptDownload(model) {
      var modelId = model.dataItem.id;
      return new Promise((resolve) => {
        this.$API.api.pc.productModel
          .get(modelId, ApiConfig.getJsonConfig)
          .then((res) => {
            console.log('res.data', res.data);
            var iptlink = res.data.downloadLinks.iptFile;
            if (iptlink == null) {
              this.fileType = 'IPT';
              this.modelFileNullDialog = true;
            } else {
              window.open(iptlink);
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            resolve();
          });
      });
    },
    stpDownload(model) {
      var modelId = model.dataItem.id;
      return new Promise((resolve) => {
        this.$API.api.pc.productModel
          .get(modelId, ApiConfig.getJsonConfig)
          .then((res) => {
            var stplink = res.data.downloadLinks.stpFile;
            if (stplink == null) {
              this.fileType = 'STEP';
              this.modelFileNullDialog = true;
            } else {
              window.open(stplink);
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            resolve();
          });
      });
    },
    dxfDownload(model) {
      var modelId = model.dataItem.id;
      return new Promise((resolve) => {
        this.$API.api.pc.productModel
          .get(modelId, ApiConfig.getJsonConfig)
          .then((res) => {
            var dxflink = res.data.downloadLinks.dxfFile;
            if (dxflink == null) {
              this.fileType = 'DXF';
              this.modelFileNullDialog = true;
            } else {
              window.open(dxflink);
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            resolve();
          });
      });
    },
    /**提交功能 */
    async pendingDialogOpen() {
      await this.loadPendingAmount();
      this.pendingDialog.value = true;
      var version = cloneDeep(this.version);
      // Vue.set(version, 'itemName', this.item.name);
      Vue.set(version, 'pendingAmount', this.pendingAmount);
      this.pendingDialog.pendingItem = version;
    },
    pendingConfirm() {
      this.statusChangeLoading = true;
      this.pendingDialog.value = false;
      var versionId = this.pendingDialog.pendingItem.id;
      this.$API.api.pc.productVersion
        .post(versionId, ApiConfig.getJsonConfig)
        .then(() => {
          let msg = '提交成功';
          this.$store.commit('notification/SET_ALERT', {
            type: 'success',
            message: msg,
            value: true,
          });
          this.version.status += 1;
          this.getStatus();
        })
        .catch((error) => {
          console.error(error);
          this.$store.commit('notification/SET_DIALOG', {
            title: 'Error',
            message: error,
            value: true,
          });
        })
        .finally(() => {
          this.statusChangeLoading = false;
        });
    },
    /**暫停功能 */
    pauseDialogOpen() {
      this.pauseDialog.value = true;
      this.pauseDialog.pauseItem = this.version;
    },
    pauseConfirm() {
      this.statusChangeLoading = true;
      this.pauseDialog.value = false;
      var versionId = this.pauseDialog.pauseItem.id;
      var fromStatus = this.version.status;
      this.$API.api.pc.productVersionStatus
        .changeStatus(versionId, fromStatus, 2, ApiConfig.getJsonConfig)
        .then(() => {
          let msg = '已暫停';
          this.$store.commit('notification/SET_ALERT', {
            type: 'success',
            message: msg,
            value: true,
          });
          this.version.status = 2;
          this.getStatus();
        })
        .catch((error) => {
          console.error(error);
          this.$store.commit('notification/SET_DIALOG', {
            title: 'Error',
            message: error,
            value: true,
          });
        })
        .finally(() => {
          this.statusChangeLoading = false;
        });
    },
    /**繼續功能 */
    async continueDialogOpen() {
      this.continueDialog.value = true;
      this.continueDialog.continueItem = this.version;
      var versionId = this.version.id;
      var res = await this.$API.api.pc.productVersion.getContinueAmount(
        versionId,
        ApiConfig.getJsonConfig
      );
      Vue.set(this.continueDialog.continueItem, 'continueModelCount', res.data);
    },
    continueConfirm() {
      this.statusChangeLoading = true;
      this.continueDialog.value = false;
      var versionId = this.continueDialog.continueItem.id;
      this.$API.api.pc.productVersion
        .postContinue(versionId, ApiConfig.getJsonConfig)
        .then(() => {
          let msg = '提交成功';
          this.$store.commit('notification/SET_ALERT', {
            type: 'success',
            message: msg,
            value: true,
          });
          this.version.status = 6;
          this.getStatus();
        })
        .catch((error) => {
          console.error(error);
          this.$store.commit('notification/SET_DIALOG', {
            title: 'Error',
            message: error,
            value: true,
          });
        })
        .finally(() => {
          this.statusChangeLoading = false;
        });
    },
    /**重試功能 */
    async retryDialogOpen() {
      //先load需要重試的數量
      this.loadingDialog = true;
      var versionId = this.version.id;
      await this.$API.api.pc.productVersion
        .getModelsStatus(versionId, ApiConfig.getJsonConfig)
        .then((res) => {
          var failed = res.data.Failed;
          Vue.set(this.version, 'failedAmount', failed);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingDialog = false;
          this.retryDialog.value = true;
          this.retryDialog.retryItem = this.version;
        });
    },
    retryConfirm() {
      this.statusChangeLoading = true;
      this.retryDialog.value = false;
      var versionId = this.retryDialog.retryItem.id;
      this.$API.api.pc.productVersion
        .retry(versionId, ApiConfig.getJsonConfig)
        .then(() => {
          let msg = '重試成功';
          this.$store.commit('notification/SET_ALERT', {
            type: 'success',
            message: msg,
            value: true,
          });
          this.version.status = 6;
          this.getStatus();
        })
        .catch((error) => {
          console.error(error);
          this.$store.commit('notification/SET_DIALOG', {
            title: 'Error',
            message: error,
            value: true,
          });
        })
        .finally(() => {
          this.statusChangeLoading = false;
        });
    },
    /**啟用功能 */
    enableDialogOpen() {
      this.enableDialog.value = true;
      this.enableDialog.enableItem = this.version;
    },
    enableConfirm() {
      this.statusChangeLoading = true;
      this.enableDialog.value = false;
      var versionId = this.enableDialog.enableItem.id;
      var fromStatus = this.version.status;
      this.$API.api.pc.productVersionStatus
        .changeStatus(versionId, fromStatus, 0, ApiConfig.getJsonConfig)
        .then(() => {
          let msg = '啟用成功';
          this.$store.commit('notification/SET_ALERT', {
            type: 'success',
            message: msg,
            value: true,
          });
          this.version.status = 0;
          this.getStatus();
        })
        .catch((error) => {
          console.error(error);
          this.$store.commit('notification/SET_DIALOG', {
            title: 'Error',
            message: error,
            value: true,
          });
        })
        .finally(() => {
          this.statusChangeLoading = false;
        });
    },

    /**作廢功能 */
    voidDialogOpen() {
      this.voidDialog.value = true;
      this.voidDialog.voidItem = this.version;
    },
    voidConfirm() {
      this.statusChangeLoading = true;
      this.voidDialog.value = false;
      var versionId = this.voidDialog.voidItem.id;
      var fromStatus = this.version.status;
      this.$API.api.pc.productVersionStatus
        .changeStatus(versionId, fromStatus, 4, ApiConfig.getJsonConfig)
        .then(() => {
          let msg = '已作廢';
          this.$store.commit('notification/SET_ALERT', {
            type: 'success',
            message: msg,
            value: true,
          });
          this.version.status = 4;
          this.getStatus();
        })
        .catch((error) => {
          console.error(error);
          this.$store.commit('notification/SET_DIALOG', {
            title: 'Error',
            message: error,
            value: true,
          });
        })
        .finally(() => {
          this.statusChangeLoading = false;
        });
    },
    /**檢視內容 打開forgeVuer */
    openForge(model) {
      this.loadingDialog = true;
      var modelId = model.dataItem.id;
      return new Promise((resolve) => {
        this.$API.api.pc.productModel
          .get(modelId, ApiConfig.getJsonConfig)
          .then((res) => {
            this.model = res.data;
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.loadingDialog = false;
            this.forgeVuerDialog = true;
            resolve();
          });
      });
    },
    /**讀取剩餘額度 */
    async loadBalance() {
      var companyId = this.companyId;
      var start = null;
      var end = null;
      var res = await this.$API.api.pc.companyProcessingUsage.getBalance(
        companyId,
        start,
        end,
        ApiConfig.getJsonConfig
      );
      this.balance = res.data;
    },
    loadPendingAmount() {
      var versionId = this.version.id;
      return new Promise((resolve) => {
        this.$API.api.pc.productVersion
          .getPendingAmount(versionId, ApiConfig.getJsonConfig)
          .then((res) => {
            this.pendingAmount = res.data;
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            resolve();
          });
      });
    },
    isAllowedChange() {
      var versionId = this.version.id;
      var isAllowed = this.allowedCreateOnDemand;
      return new Promise((resolve) => {
        this.$API.api.pc.productVersion
          .setIsAllowed(versionId, isAllowed, ApiConfig.getJsonConfig)
          .then(() => {
            this.$emit('versionIsAllowedChange', versionId);
            if (this.allowedCreateOnDemand) {
              let msg = '即時轉檔已啟用';
              this.$store.commit('notification/SET_ALERT', {
                type: 'success',
                message: msg,
                value: true,
              });
            } else {
              let msg = '即時轉檔已關閉';
              this.$store.commit('notification/SET_ALERT', {
                type: 'success',
                message: msg,
                value: true,
              });
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            resolve();
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#custom-disabled.v-btn--disabled {
  color: #006ad8 !important;
}
</style>
