<template>
  <v-card style="height: 100%"
    ><v-card-title class="pa-3 pl-4 pr-3">
      <v-btn
        v-if="mode !== 'itemCard'"
        icon
        class="mr-2"
        @click="modeToItemCard()"
        ><v-icon>mdi-arrow-left</v-icon></v-btn
      ><v-divider v-if="mode !== 'itemCard'" class="pr-0 mr-3" vertical />
      {{ product.name
      }}<v-tooltip right v-if="mode == 'itemCard'">
        <template v-slot:activator="{ on }">
          <span v-on="on" style="font-weight: 500; margin-top: -5px"
            ><v-badge
              :content="countItems !== 0 ? countItems : '0'"
              inline
              color="#A0A0A0"
              class="ma-0 ml-1"
            ></v-badge>
          </span>
        </template>
        <span>元件數量</span>
      </v-tooltip>
      <v-icon v-if="mode !== 'itemCard'">mdi-chevron-right</v-icon
      ><span v-if="mode == 'disabledCard'">停用零組件</span>
      <span v-if="mode == 'moveItemCard'">批次移動</span>

      <v-spacer />

      <v-tooltip bottom v-if="mode == 'itemCard' && !readOnly">
        <template v-slot:activator="{ on }">
          <v-btn
            large
            icon
            color="primary"
            v-on="on"
            class="mr-1"
            @click="resultUpload()"
            ><v-icon>mdi-cloud-upload</v-icon></v-btn
          >
        </template>

        <span>上傳檔案</span>
      </v-tooltip>
      <v-tooltip bottom v-if="!sortbyName && mode == 'itemCard'">
        <template v-slot:activator="{ on }">
          <v-btn
            large
            icon
            color="primary"
            v-on="on"
            class="mr-1"
            @click="sortByName()"
            ><v-icon>mdi-sort-alphabetical-ascending</v-icon></v-btn
          >
        </template>

        <span>依名稱排序</span>
      </v-tooltip>
      <v-tooltip bottom v-if="sortbyName && mode == 'itemCard'">
        <template v-slot:activator="{ on }">
          <v-btn
            large
            icon
            color="primary"
            v-on="on"
            class="mr-1"
            @click="sortByTime()"
            ><v-icon>mdi-sort-clock-descending</v-icon></v-btn
          >
        </template>

        <span>依修改時間排序</span>
      </v-tooltip>
      <v-menu offset-y left v-if="!readOnly">
        <template v-slot:activator="{ on }">
          <v-btn large icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list flat class="pa-0">
          <v-list-item-group color="primary">
            <v-list-item>
              <v-list-item-content @click="modeToDisabledCard()">
                <v-list-item-title>檢視停用零組件</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content @click="modeToMoveItemCard()">
                <v-list-item-title>批次移動</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-divider />
    <v-card
      v-if="skeleteonLoading"
      flat
      class="pb-1"
      style="
        height: calc(100% - 68px);
        overflow-y: scroll;
        background-color: #f8f8f8;
      "
      :loading="loading"
      ><SkeletonList v-for="item in countCards" :key="item" />
    </v-card>
    <v-card
      v-if="!skeleteonLoading"
      flat
      style="height: calc(100% - 68px); background-color: #f8f8f8"
      :loading="loading"
      ><v-card
        v-on:scroll.native="handleScroll"
        id="listCard"
        flat
        class="pb-1"
        color="transparent"
        :style="
          mode == 'moveItemCard'
            ? 'height: calc(100% - 64px) ;overflow-y: scroll;'
            : 'height: 100% ;overflow-y: scroll;'
        "
      >
        <ItemListCard
          ref="itemListCard"
          v-if="mode == 'itemCard'"
          :items="items"
          :searchItemArr="searchItemArr"
          :itemIdOpen="itemIdOpen"
          :readOnly="readOnly"
          :loading="loading"
          @reload="reload"
          @viewVersions="viewVersions"
          @moveItem="moveItem"
          @searchItem="searchItem"
        />
        <DisabledItemCard
          v-if="mode == 'disabledCard'"
          :items="items"
          :itemIdOpen="itemIdOpen"
          :loading="loading"
          @reload="reload"
          @viewVersions="viewVersions"
        />
        <MoveItemCard
          ref="moveItemCard"
          v-if="mode == 'moveItemCard'"
          :items="items"
          :loading="loading"
          :searchItemArr="searchItemArr"
          @moveMultiDialogOpen="moveMultiDialogOpen"
          @checkChange="checkChange"
        />
      </v-card>
      <BottomCard
        v-if="mode == 'moveItemCard'"
        :companyId="companyId"
        @selectAll="selectAll"
        @clearAll="clearAll"
        @cancelMove="cancelMove"
        @moveItemClick="moveItemClick"
        @moveAllItem="moveAllItem"
        @getGroupName="getGroupName"
    /></v-card>
    <v-dialog v-model="moveDialog" max-width="400">
      <MoveItemDialog
        :companyId="companyId"
        :item="item"
        @moveDialogClose="moveDialogClose"
        @moveItemConfirm="moveItemConfirm"
    /></v-dialog>
    <v-dialog v-model="moveMultiDialog" max-width="400">
      <MoveMultiDialog
        :movingItems="movingItems"
        :selectedGName="selectedGName"
        @moveMultiDialogClose="moveMultiDialogClose"
        @moveMultiConfirm="moveMultiConfirm"
    /></v-dialog>
    <LoadingDialog v-model="loadingDialog" />
  </v-card>
</template>

<script>
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import ApiConfig from '../../../plugins/ApiConfig.js';
import ItemListCard from './ItemListCard';
import DisabledItemCard from './DisabledItemCard';
import MoveItemCard from './MoveItemCard';
import MoveItemDialog from '../../Dialog/MoveItemDialog';
import MoveMultiDialog from '../../Dialog/MoveMultiDialog';
import SkeletonList from './SkeletonList';
import BottomCard from './BottomCard';
import LoadingDialog from '../../Dialog/LoadingDialog.vue';
export default {
  components: {
    ItemListCard,
    DisabledItemCard,
    MoveItemCard,
    MoveItemDialog,
    MoveMultiDialog,
    SkeletonList,
    BottomCard,
    LoadingDialog,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    companyId: {
      type: String,
      required: true,
    },
    searchText: {
      type: String,
    },
    itemIdOpen: {
      type: String,
    },
    isEnable: {
      type: String,
    },
    readOnly: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      skeleteonLoading: true,
      loadingDialog: false,
      mode: 'itemCard',
      sortbyName: false,
      groupId: '',
      originalItems: [],
      page: 1,
      take: 15,
      total: 0,
      countItems: 0,
      countCards: 0,
      item: {},
      items: [
        // {
        // id: "fef7c080-107b-4e98-9258-33f439186af1",
        // name: "P.68.zip"
        // publishVersionId: null,
        // publishVersionNumber: null,
        // isEnable: true, //停用
        // isPublic: true, //權限
        // link: null,
        // viewCount: 0,  //查閱次數
        // }
      ],
      dataState: {
        take: 15,
        skip: 0,
        sort: [{ field: 'modifiedDate', dir: 'desc' }],
        // sort: [{ field: "name", dir: "asc" }],
      },
      moveDialog: false,
      moveMultiDialog: false,
      versionOpen: false, //是否開啟版本管理
      searchItemArr: [],
      selectedGName: '未分類群組',
      selectedGroup: {},
      movingItems: [],
      selectAllCheck: false,
    };
  },
  watch: {
    product: {
      handler(val) {
        this.skeleteonLoading = true;
        this.items = [];
        this.page = 1;
        this.loadItems(val);
        this.mode = 'itemCard';
        this.countItems = val.itemsCount;
        if (val.itemsCount < 5) {
          this.countCards = val.itemsCount;
        } else {
          this.countCards = 5;
        }
      },
      deep: true,
    },
    itemIdOpen: {
      handler(val) {
        console.log('itemIdOpen from RightCard', val);
      },
      deep: true,
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    if (
      this.searchText !== null &&
      this.searchText !== undefined &&
      this.searchText !== ''
    ) {
      this.searchItem(this.searchText, this.product);
    }

    this.loadItems(this.product);
    this.countItems = this.product.itemsCount;
    if (this.isEnable == 'false') {
      this.modeToDisabledCard();
    }
  },
  methods: {
    /**讀取群組內所有元件 */
    async loadItems(product, upload, apply) {
      this.loading = true;
      this.dataState.skip = (this.page - 1) * this.take;
      var dataState = this.dataState;
      const queryStr = toDataSourceRequestString(dataState);
      return new Promise((resolve) => {
        if (product.name == '未分類群組') {
          this.$API.api.pc.companyDefaultProductGroup
            .get(this.companyId, queryStr, ApiConfig.getJsonConfig)
            .then((res) => {
              this.total = res.data.total;
              this.addCheck(res.data.data, upload, apply);
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              this.loading = false;
              this.$emit('loadingEnd');
              this.skeleteonLoading = false;
              this.loadThumbnail(this.items);
              resolve();
            });
        } else {
          this.groupId = product.id;
          this.$API.api.pc.productGroupItem
            .get(this.groupId, queryStr, ApiConfig.getJsonConfig)
            .then((res) => {
              this.total = res.data.total;
              this.addCheck(res.data.data, upload, apply);
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              this.loading = false;
              this.$emit('loadingEnd');
              this.skeleteonLoading = false;
              this.loadThumbnail(this.items);
              resolve();
            });
        }
      });
    },
    /**取得縮圖 */
    loadThumbnail(items) {
      for (let i = 0; i < items.length; i++) {
        var itemId = items[i].id;
        this.$API.api.pc.productItem
          .getThumbnailBase64(itemId, ApiConfig.getJsonConfig)
          .then((res) => {
            var imageBase64 = 'data:image/png;base64,' + res.data;
            Vue.set(items[i], 'image', imageBase64);
          })
          .catch((error) => {
            console.error(error);
            Vue.set(items[i], 'image', 'notFound');
          })
          .finally(() => {});
      }
    },
    /**取得查閱次數 */
    loadViewCount(items) {
      for (let i = 0; i < items.length; i++) {
        var itemId = items[i].id;
        this.$API.api.pc.productItemView
          .get(itemId, ApiConfig.getJsonConfig)
          .then((res) => {
            Vue.set(items[i], 'viewCounts', res.data);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {});
      }
    },
    addCheck(data, upload, apply) {
      var items = cloneDeep(data);
      for (let i = 0; i < items.length; i++) {
        Vue.set(items[i], 'check', false);
      }
      this.catLists(items, upload, apply);
    },
    /** scroll paging */
    handleScroll() {
      var scrollHeight = document.getElementById('listCard').scrollHeight;
      var cardHeight = document.getElementById('listCard').clientHeight;
      var scrollMove = document.getElementById('listCard').scrollTop;
      var scrollToBottom = scrollHeight < cardHeight + scrollMove + 126 * 5;

      if (scrollToBottom && !this.loading) {
        this.page += 1;

        if ((this.page - 1) * this.take < this.total) {
          this.loadItems(this.product);
        }
      }
    },
    /**scrolling page 加上新load的data */
    catLists(data, upload, apply) {
      if (apply == true) {
        this.items = data;
      } else {
        var origin = cloneDeep(this.items);
        var catArr = origin.concat(data);
        this.items = catArr;
      }
      if (this.versionOpen) {
        this.afterApply(upload, apply);
      }
      this.loadViewCount(this.items);
    },
    // 上傳檔案
    resultUpload() {
      // var companyId = this.companyId;
      this.$router.push({
        name: 'Upload',
        params: {
          group: this.product,
          companyId: this.companyId,
        },
      });
    },
    /**移動元件至其他群組 */
    moveItem(item) {
      this.item = item;
      this.moveDialog = true;
    },
    moveItemConfirm(group) {
      this.moveDialog = false;
      var groupId = group.id;
      var itemId = this.item.id;
      return new Promise((resolve) => {
        this.$API.api.pc.productItem
          .moveToGroup(itemId, groupId, ApiConfig.getJsonConfig)
          .then(() => {})
          .catch((error) => {
            console.error(error);
            this.$store.commit('notification/SET_DIALOG', {
              title: 'Error',
              message: error,
              value: true,
            });
          })
          .finally(() => {
            this.reload();
            this.countItems = this.countItems - 1;
            let message = '移動成功';
            this.$store.commit('notification/SET_ALERT', {
              type: 'success',
              message: message,
              value: true,
            });
            resolve();
          });
      });
    },
    moveDialogClose() {
      this.moveDialog = false;
    },
    getLink() {
      let msg = '已複製連結';
      this.$store.commit('notification/SET_ALERT', {
        type: 'success',
        message: msg,
        value: true,
      });
    },
    viewVersions(item, upload, apply) {
      this.$emit('viewVersions', item, this.versionOpen, upload, apply);
      this.item = item;
      this.versionOpen = true;
    },
    closeVersion() {
      this.versionOpen = false;
    },
    /**進行刪除、移動等動作時重新讀取 */
    reload(del) {
      this.items = [];
      this.page = 1;
      if (del) {
        this.countItems = this.countItems - 1;
      }
      this.loadItems(this.product);
      this.$emit('reload', this.product);
    },
    afterApply(upload, apply) {
      var item = {};
      for (let i = 0; i < this.items.length; i++) {
        if (this.item.id == this.items[i].id) {
          item = this.items[i];
        }
      }
      this.viewVersions(item, upload, apply);
    },
    /**依照名稱進行排序 */
    sortByName() {
      this.items = [];
      this.page = 1;
      this.sortbyName = true;
      this.dataState = {
        sort: [{ field: 'name', dir: 'asc' }],
        take: 15,
      };
      this.reload();
    },
    /**依照名稱進行排序 */
    sortByTime() {
      this.items = [];
      this.page = 1;
      this.sortbyName = false;
      this.dataState = {
        sort: [{ field: 'modifiedDate', dir: 'desc' }],
        take: 15,
      };
      this.reload();
    },
    /**使用搜尋欄搜尋並標記元件 */
    searchItem(searchText, product) {
      var dataState = {
        sort: [{ field: 'modifiedDate', dir: 'desc' }],
        filter: {
          filters: [
            {
              logic: 'or',
              filters: [
                {
                  field: 'name',
                  operator: 'contains',
                  value: searchText,
                },
              ],
            },
          ],
          logic: 'or',
        },
      };
      const queryStr = toDataSourceRequestString(dataState);
      if (product.name == '未分類群組') {
        this.$API.api.pc.companyDefaultProductGroup
          .get(this.companyId, queryStr, ApiConfig.getJsonConfig)
          .then((res) => {
            this.searchItemArr = res.data.data;
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.loading = false;
            this.$emit('loadingEnd');
          });
      } else {
        var groupId = product.id;
        this.$API.api.pc.productGroupItem
          .get(groupId, queryStr, ApiConfig.getJsonConfig)
          .then((res) => {
            this.searchItemArr = res.data.data;
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.loading = false;
            this.$emit('loadingEnd');
          });
      }
    },
    /**批次移動目標 */
    getGroupName(name, group) {
      this.selectedGName = name;
      this.selectedGroup = group;
    },
    cancelMove() {
      this.mode = 'itemCard';
      this.clearAll();
    },
    moveItemClick() {
      this.$refs.moveItemCard.getMoveingItems();
      // this.moveMultiDialog = true;
    },
    async moveAllItem() {
      this.loadingDialog = true;
      this.selectAllCheck = true;
      var movingItems = [];
      var items = await this.geAllItems();
      for (let i = 0; i < items.length; i++) {
        if (items[i].isEnable) {
          movingItems.push(items[i]);
        }
      }
      this.loadingDialog = false;
      this.moveMultiDialogOpen(movingItems);
    },
    /**全選移動時，items不一定全部取得，需另外拿全部的items以顯示在確定移動的dialog上 */
    async geAllItems() {
      var dataState = {};
      const queryStr = toDataSourceRequestString(dataState);
      if (this.product.name == '未分類群組') {
        var res = await this.$API.api.pc.companyDefaultProductGroup.get(
          this.companyId,
          queryStr,
          ApiConfig.getJsonConfig
        );
      } else {
        var groupId = this.product.id;
        res = await this.$API.api.pc.productGroupItem.get(
          groupId,
          queryStr,
          ApiConfig.getJsonConfig
        );
      }
      return res.data.data;
    },
    moveMultiDialogOpen(movingItems) {
      this.moveMultiDialog = true;
      this.movingItems = movingItems;
    },
    moveMultiDialogClose() {
      this.moveMultiDialog = false;
      this.selectAllCheck = false;
    },

    /**進行批次移動 */
    moveMultiConfirm() {
      this.moveMultiDialog = false;
      this.loadingDialog = true;
      if (this.selectAllCheck) {
        this.moveAll();
      } else {
        this.moveFormList();
      }
    },
    moveFormList() {
      var groupId = this.selectedGroup.id;
      for (let i = 0; i < this.movingItems.length; i++) {
        var itemId = this.movingItems[i].id;
        this.$API.api.pc.productItem
          .moveToGroup(itemId, groupId, ApiConfig.getJsonConfig)
          .then(() => {
            // console.log("res", res);
          })
          .catch((error) => {
            console.error(error);
            this.$store.commit('notification/SET_DIALOG', {
              title: 'Error',
              message: error,
              value: true,
            });
          })
          .finally(() => {
            if ((i = this.movingItems.length - 1)) {
              let message = '移動成功';
              this.$store.commit('notification/SET_ALERT', {
                type: 'success',
                message: message,
                value: true,
              });
            }
          });
      }
      this.loadingDialog = false;
      this.reload();
    },
    moveAll() {
      var groupId = this.product.id;
      var toGroupId = this.selectedGroup.id;
      this.$API.api.pc.productGroupItem
        .moveAllItem(groupId, toGroupId, ApiConfig.getJsonConfig)
        .then(() => {
          // console.log("res", res);
        })
        .catch((error) => {
          console.error(error);
          this.$store.commit('notification/SET_DIALOG', {
            title: 'Error',
            message: error,
            value: true,
          });
        })
        .finally(() => {
          this.reload();
          let message = '移動成功';
          this.$store.commit('notification/SET_ALERT', {
            type: 'success',
            message: message,
            value: true,
          });
          this.loadingDialog = false;
          this.selectAllCheck = false;
        });
    },
    /**切換到itemCard*/
    modeToItemCard() {
      this.mode = 'itemCard';
      this.$emit('removeIsEnableFromRoute');
      this.clearAll();
    },
    /**切換到disabledCard */
    modeToDisabledCard() {
      this.mode = 'disabledCard';
      this.$emit('addIsEnableToLocation', false);
    },
    /**切換到moveItemCard*/
    modeToMoveItemCard() {
      this.mode = 'moveItemCard';
      this.$emit('removeIsEnableFromRoute');
    },
    /**moveItemCard 點選 */
    checkChange(item) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].id === item.id) {
          this.items[i].check = item.check;
        }
      }
    },
    /**moveItemCard 全選與全不選 */
    selectAll() {
      var items = cloneDeep(this.items);
      for (let i = 0; i < items.length; i++) {
        items[i].check = true;
      }
      this.items = items;
    },
    clearAll() {
      var items = cloneDeep(this.items);
      for (let i = 0; i < items.length; i++) {
        items[i].check = false;
      }
      this.items = items;
    },
  },
};
</script>

<style lang="scss" scoped></style>
