<template>
  <div>
    <v-card>
      <v-card-title>剩餘點數不足</v-card-title>
      <v-card-text>無剩餘點數，無法進行檔案上傳，請進行加值</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="noQuotaDialogClose()">關閉</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      files: [],
      multiple: false,
      loading: false,
    };
  },
  methods: {
    noQuotaDialogClose() {
      this.$emit("noQuotaDialogClose");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles.scss";
</style>
