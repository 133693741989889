<template>
  <v-card style="overflow-y: scroll; height:100%" :loading="loading">
    <v-list two-line class="pa-0" :disabled="disabled">
      <v-list-item-group v-model="selected" active-class="primary--text">
        <template v-for="(item, index) in myGroups">
          <v-list-item
            :key="item.index"
            :style="item.editOpen ? 'height: 145px' : 'height: 81px'"
          >
            <v-list-item-content class="pt-4 pb-4 pl-1">
              <!-- edit版本 -->
              <v-list-item-title
                style="font-weight: 500"
                class="mb-1"
                v-if="item.editOpen"
              >
                <v-text-field
                  v-model="editName"
                  label="編輯群組名稱"
                  hide-details
                  @click.stop
                ></v-text-field
                ><v-btn
                  small
                  outlined
                  color="primary"
                  class="mr-2 mt-3 mb-1"
                  @click.stop="item.editOpen = false"
                  >取消</v-btn
                ><v-btn
                  small
                  depressed
                  dark
                  color="primary"
                  class="mt-3 mb-1"
                  @click.stop="groupNameChange(item)"
                  >確認更改</v-btn
                >
              </v-list-item-title>
              <v-list-item-title
                style="font-weight: 700"
                class="mb-1"
                v-if="!item.editOpen"
                >{{ item.name }}
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <span v-on="on" style="font-weight: 500; margin-top: -10px"
                      ><v-badge
                        :content="item.itemsCount !== 0 ? item.itemsCount : '0'"
                        inline
                        color="#A0A0A0"
                        :dark="false"
                        class="ma-0"
                      ></v-badge>
                    </span>
                  </template>
                  <span>元件數量</span>
                </v-tooltip>
              </v-list-item-title>

              <v-list-item-subtitle
                v-if="
                  item.modifiedUserName !== null && item.modifiedUserName !== ''
                "
                v-text="
                  '最後更新：' +
                    item.modifiedUserName +
                    ', ' +
                    item.modifiedDate
                "
              ></v-list-item-subtitle>
              <v-list-item-subtitle
                v-if="
                  item.modifiedUserName == null || item.modifiedUserName == ''
                "
                v-text="'無編輯紀錄'"
              ></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action
              v-if="!readOnly && !item.editOpen && item.id !== null"
            >
              <v-icon class="mr-2" @click.stop="editGroupName(item)">
                mdi-pencil
              </v-icon>
            </v-list-item-action>
          </v-list-item>

          <v-divider v-if="index < myGroups.length" :key="index"></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import ApiConfig from '../../plugins/ApiConfig.js';
import DateFormat from '../../plugins/DateFormat';
export default {
  props: {
    companyId: {
      type: String,
      required: true,
    },
    uploadGroup: {
      type: Object,
    },
    openVersion: {
      type: Object,
    },
    sorting: {
      type: Number,
    },
    groupIdOpen: {
      type: String,
    },
    readOnly: {
      type: Boolean,
    },
  },
  data() {
    return {
      selected: undefined,
      editName: '',
      myGroups: [
        {
          id: null,
          name: '未分類群組',
          itemsCount: 0,
          modifiedUserName: '',
          modifiedUserId: '',
          modifiedDate: '',
          editOpen: false,
        },
      ],
      groups: [
        //{
        //   id: "48a1c115-2903-4ff9-a7af-d6468e41bac1",
        //   name: "Test_GGJason",
        //   itemsCount: 1,
        //   modifiedUserName: "吳軒"
        //   modifiedUserId: "93037021-b753-4412-a4c5-40442cafbbc4"
        //   modifiedDate: "2021-01-19T08:34:41.50209+00:00"
        //   editOpen: false,
        // },
      ],
      originalGroups: [],
      // defaultGroupItems: [],
      dataState: {
        page: 0,
        filter: null,
      },
      searchText: null,
      loading: true,
      loadingDefaultGroup: true,
      loadingDefaultGroupAmount: true,
      disabled: true,
    };
  },
  watch: {
    selected: {
      handler(val) {
        if (val == undefined) {
          this.productClear();
        } else {
          this.disabled = true;
          this.$emit('addGroupToLocation', this.myGroups[val]);
          this.getProductItems(this.myGroups[val]);
          if (
            this.searchText !== null &&
            this.searchText !== undefined &&
            this.searchText !== ''
          ) {
            this.$emit('searchItem', this.searchText);
          }
        }
      },
      deep: true,
    },
    myGroups: {
      handler(val) {
        if (
          this.searchText !== null &&
          this.searchText !== undefined &&
          this.searchText !== ''
        ) {
          if (val.length == 1) {
            this.getProductItems(val[0]);
            this.$emit('searchItem', this.searchText, val[0]);
          } else {
            this.getProductItems(val[1]);
            this.$emit('searchItem', this.searchText, val[1]);
          }
        }
        if (val[0].itemsCount !== 0) {
          this.loadDefaultGroupDetail();
        }
      },
      deep: true,
    },
    sorting: {
      handler() {
        this.sortGroups();
        this.setMygroups();
        this.loadDefaultGroupAmount();
      },
      deep: true,
    },
    groupIdOpen: {
      handler(val) {
        if (val) {
          this.openGroup();
        }
      },
      deep: true,
    },
    disabled: {
      handler(val) {
        this.$emit('searchDisabledChange', val);
      },
      deep: true,
    },
    companyId: {
      handler() {
        this.init();
        this.selected = undefined;
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.loadDefaultGroupDetail();
      await this.loadGroups();
      if (this.groupIdOpen !== undefined) {
        this.openGroup();
      }
      if (this.uploadGroup !== undefined) {
        this.routeGroup(this.uploadGroup);
      }
      if (this.openVersion !== undefined) {
        this.routeVersion(this.openVersion);
      }
    },
    openGroup() {
      for (let i = 0; i < this.myGroups.length; i++) {
        if (this.myGroups[i].id === this.groupIdOpen) {
          this.selected = i;
        } else if (this.groupIdOpen == 'null') {
          this.selected = 0;
        }
      }
    },
    /**讀取未分類群組詳細資訊 */
    async loadDefaultGroupDetail() {
      this.loadingDefaultGroup = true;
      var companyId = this.companyId;

      return new Promise((resolve) => {
        this.$API.api.pc.companyDefaultProductGroup
          .getDetail(companyId, ApiConfig.getJsonConfig)
          .then((res) => {
            var data = res.data.data;
            this.myGroups[0].modifiedUserName = data.modifiedUserName;
            this.myGroups[0].modifiedUserId = data.modifiedUserId;
            this.myGroups[0].modifiedDate = DateFormat.dateForVuetify(
              data.modifiedDate,
              true,
              true
            );
          })
          .catch((error) => {
            console.error(error);
            if (error.response.status === 403) {
              this.$emit('forbiddenOpen');
            }
          })
          .finally(() => {
            this.loadingDefaultGroup = false;
            if (!this.loadingDefaultGroupAmount) {
              this.disabled = false;
              this.loading = false;
            }

            resolve();
          });
      });
    },
    /**讀取所有群組 */
    async loadGroups() {
      this.loading = true;
      this.disabled = true;
      var companyId = this.companyId;
      var dataState = this.dataState;
      const queryStr = toDataSourceRequestString(dataState);
      var res = await this.$API.api.pc.companyProductGroup.get(
        companyId,
        this.searchText,
        queryStr,
        ApiConfig.getJsonConfig
      );
      var data = res.data.data;
      this.originalGroups = data;
      this.groups = cloneDeep(this.originalGroups);
      //加上editOpen
      for (let i = 0; i < this.groups.length; i++) {
        Vue.set(this.groups[i], 'editOpen', false);
      }
      this.changeDateFormat();
      this.sortGroups();
      this.setMygroups();
      if (!this.loadingDefaultGroup || !this.loadingDefaultGroupAmount) {
        this.disabled = false;
        this.loading = false;
      }
    },
    /**排序 */
    sortGroups() {
      return this.groups.sort(
        (a, b) =>
          a.name.toLowerCase().trim() > b.name.toLowerCase().trim()
            ? this.sorting
            : -this.sorting
        // a.name > b.name ? this.sorting : -this.sorting
      );
    },
    /**讀取預設群組內元件數量 */
    async loadDefaultGroupAmount() {
      this.loadingDefaultGroupAmount = true;
      var res = await this.$API.api.pc.companyDefaultProductGroup.getAmount(
        this.companyId,
        ApiConfig.getJsonConfig
      );
      this.myGroups[0].itemsCount = res.data.data.itemsCount;
      this.loadingDefaultGroupAmount = false;
      if (!this.loadingDefaultGroup) {
        this.disabled = false;
        this.loading = false;
      }
    },
    setMygroups() {
      this.myGroups = [
        {
          id: null,
          name: '未分類群組',
          itemsCount: 0,
          modifiedUserName: null,
          modifiedUserId: null,
          modifiedDate: null,
          editOpen: false,
        },
      ];
      for (let i = 0; i < this.groups.length; i++) {
        this.myGroups.push(this.groups[i]);
      }
      this.loadDefaultGroupAmount();
    },
    /**處理時間格式 */
    changeDateFormat() {
      for (let i = 0; i < this.groups.length; i++) {
        this.groups[i].modifiedDate = DateFormat.dateForVuetify(
          this.groups[i].modifiedDate,
          true,
          true
        );
      }
    },
    editGroupName(group) {
      for (let i = 0; i < this.groups.length; i++) {
        this.groups[i].editOpen = false;
      }
      group.editOpen = true;
      this.editName = cloneDeep(group.name);
    },
    getProductItems(product) {
      this.$emit('getProductItems', product);
    },
    //確認更改
    async groupNameChange(item) {
      if (this.editName.trim() !== '') {
        item.name = cloneDeep(this.editName);
        item.editOpen = false;
        var groupId = item.id;
        var newName = item.name;

        return new Promise((resolve) => {
          this.$API.api.pc.productGroup
            .put(groupId, newName, ApiConfig.getJsonConfig)
            .then(() => {})
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              resolve();
            });
        });
      }
    },
    productClear() {
      this.$emit('productClear');
    },
    /**從上傳頁面跳轉 */
    routeGroup(groupId) {
      this.myGroups.forEach((element, index) => {
        if (element.id == groupId) {
          this.selected = index;
        }
      });
    },
    /**從模型轉檔管理開啟 */
    routeVersion(openVersion) {
      this.myGroups.forEach((element, index) => {
        if (element.id == openVersion.groupId) {
          this.selected = index;
        }
      });
    },
    /**搜尋 */
    async search(e) {
      let filterString = e;
      if (filterString !== null) {
        this.searchText = filterString.trim();
      } else {
        this.searchText = filterString;
      }

      if (this.searchText === '') {
        this.searchText = null;
      }
      await this.loadGroups();
      if (this.searchText !== null && this.searchText !== undefined) {
        this.selected = 1;
        // this.$emit("searchItem", this.searchText);
      } else {
        this.selected = undefined;
        this.$emit('searchEnd');
      }
    },
    loadingEnd() {
      this.disabled = false;
    },
  },
};
</script>
