<template>
  <div>
    <!-- 新增群組 -->
    <v-tooltip bottom v-if="!readOnly">
      <template v-slot:activator="{ on }">
        <v-btn
          class="actionBtn ml-3"
          color="primary"
          v-on="on"
          @click="addGroup()"
          ><v-icon left>mdi-plus-circle</v-icon>
          <span>新增群組</span>
        </v-btn>
      </template>
      <span>新增群組</span>
    </v-tooltip>

    <!-- 批次上傳 -->
    <v-tooltip bottom v-if="!readOnly">
      <template v-slot:activator="{ on }">
        <v-btn
          class="actionBtn ml-3"
          color="primary"
          v-on="on"
          @click="resultUpload()"
          ><v-icon left>mdi-cloud-upload</v-icon>
          <span>批次上傳</span>
        </v-btn>
      </template>
      <span>批次上傳</span>
    </v-tooltip>

    <!-- 排序 -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          class="actionBtn ml-3"
          color="primary"
          v-on="on"
          @click="sortGroup()"
          ><v-icon v-if="!sortAsc" left>mdi-sort-alphabetical-ascending</v-icon>
          <v-icon v-if="sortAsc" left>mdi-sort-alphabetical-descending</v-icon>
          <span>排序</span>
        </v-btn>
      </template>
      <span>排序</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    companyId: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
    },
  },
  data() {
    return {
      sortAsc: true,
      advancedItems: [
        {
          module: 'upload',
          iconColor: 'primary',
          icon: 'mdi-cloud-upload',
          title: '批次上傳',
          //title: this.$t("form.dataGrid.formResultUpload"),
          isVisiable: true,
        },
        {
          module: 'download',
          iconColor: 'primary',
          icon: 'mdi-cloud-download',
          title: '批次下載',
          isVisiable: true,
        },
        {
          module: 'multipleDelete',
          iconColor: 'error',
          icon: 'mdi-delete',
          title: '批次刪除',
          isVisiable: true,
        },
      ],
    };
  },
  methods: {
    /**新增群組 */
    addGroup() {
      this.$emit('addGroup');
    },
    sortGroup() {
      this.sortAsc = !this.sortAsc;
      this.$emit('sortGroup');
    },
    // 進階功能function
    advancedItemClick(key) {
      switch (key) {
        case 'exportExcel':
          this.exportExcel();
          break;
        case 'upload':
          this.resultUpload();
          break;
        case 'download':
          this.multipleDownloadMode();
          break;
        case 'multipleDelete':
          this.multipleDeleteMode();
          break;
      }
    },
    checkRole(roleFunction) {
      // 檢查App頁面的操作權限
      if (this.isPage) {
        return this.$store.getters.checkFormAppFunctions(roleFunction);
      }
      return true;
    },
    // 批次上傳
    resultUpload() {
      // var companyId = this.$route.params.companyId;
      this.$router.push({
        name: 'Upload',
        params: {
          group: this.product,
          companyId: this.companyId,
        },
      });
    },
  },
};
</script>
