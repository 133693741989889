<template>
  <v-card color="transparent" flat>
    <SkeletonVersionList v-if="loadingVersion" />
    <!-- style="height:76vh; overflow-y: scroll;"  -->
    <v-timeline v-if="!loadingVersion" dense style="margin-left: -25px">
      <v-timeline-item
        small
        v-for="item in versions"
        :key="item.id"
        :icon="item.apply ? 'mdi-check' : ''"
        :color="item.apply ? 'primary' : '#C4C4C4'"
        :fill-dot="item.apply ? true : false"
        class="ma-0"
      >
        <div>
          <v-card
            class="pt-0 ma-0"
            width="258"
            :elevation="item.active ? '4' : '2'"
            :color="item.active ? '#E0EFFF' : '#F7F7F7'"
            @click="clickVersion(item)"
            ><v-container fluid class="pa-0 ma-0" style="height: 100%">
              <v-row class="pa-0 ma-0"
                ><v-card flat style="width: 100%" color="transparent"
                  ><v-card-title class="pt-2 pl-3 pb-1 pr-1 ma-0 subtitle-1"
                    >版本 {{ item.versionNumber
                    }}<v-badge
                      v-if="!item.isPreprocessing"
                      :content="
                        item.finished !== undefined
                          ? item.finished + '/' + item.totalModelCount
                          : '讀取中'
                      "
                      inline
                      class="ma-0"
                      ><v-chip class="ml-2" small>不預處理</v-chip></v-badge
                    >

                    <v-badge
                      v-if="item.isPreprocessing"
                      :content="
                        item.finished !== undefined
                          ? item.finished + '/' + item.totalModelCount
                          : '讀取中/' + item.totalModelCount
                      "
                      inline
                      class="ma-0"
                      ><v-chip class="ml-2" small color="success"
                        >預處理<v-icon
                          v-if="!item.isAllowedCreateOnDemand"
                          class="ml-1"
                          x-small
                          >mdi-lock</v-icon
                        ></v-chip
                      ></v-badge
                    >
                    <v-spacer />
                    <v-tooltip bottom v-if="isSystemAdmin">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          color="primary"
                          v-on="on"
                          icon
                          @click.stop="downloadVersion(item)"
                          ><v-icon>mdi-download</v-icon>
                        </v-btn>
                      </template>
                      <span>下載此版本</span>
                    </v-tooltip>
                  </v-card-title></v-card
                ></v-row
              ></v-container
            ><v-divider />
            <v-container
              fluid
              class="pa-0 ma-0"
              style="height: 100%; text-align: center"
            >
              <v-row class="pa-2 ma-0"
                ><v-card
                  outlined
                  height="90"
                  width="90"
                  class="pa-1 mr-2 align-start"
                >
                  <v-skeleton-loader
                    v-if="item.image == undefined || item.image == null"
                    style="
                      margin-top: 1px;
                      margin-bottom: 1px;
                      margin-left: 1px;
                    "
                    width="78"
                    height="78"
                    type="image"
                  ></v-skeleton-loader>
                  <v-img
                    v-if="
                      item.image !== undefined &&
                      item.image !== null &&
                      item.image !== 'notFound'
                    "
                    :lazy-src="item.image"
                    :src="item.image"
                    style="margin-top: 1px"
                    contain
                    height="78"
                    width="78"
                  ></v-img>
                  <v-img
                    v-if="item.image == 'notFound'"
                    :lazy-src="noImage"
                    :src="noImage"
                    style="
                      background-color: #f0f0f0;
                      opacity: 0.7;
                      padding-top: 35px;
                      margin-top: 1px;
                      margin-left: 1px;
                    "
                    contain
                    height="78"
                    width="78"
                  ></v-img>
                </v-card>
                <v-card flat color="transparent" class="timelineTextCard mr-3">
                  <v-card-text class="timelineText">
                    {{ item ? item.createdUserName : '' }}</v-card-text
                  >
                  <v-card-text class="timelineTime"
                    >{{ item.createdDay }} {{ item.createdTime }}</v-card-text
                  >
                  <v-card-text class="timelineTime" v-if="item.createdDate"
                    ><sb-ui-time-cell :time="item.createdDate" /></v-card-text
                  ><v-card-text class="timelineTime"
                    >查閱次數：<v-chip style="font-weight: 600" small>
                      {{ item.viewCount }}
                    </v-chip>
                    <!--v-if="item.viewCount !== undefined" <v-progress-circular
                      v-if="item.viewCounts == undefined"
                      :size="18"
                      :width="3"
                      color="#B1B1B1"
                      indeterminate
                      class="mt-1 mr-3"
                    ></v-progress-circular> -->
                  </v-card-text></v-card
                ></v-row
              ></v-container
            >
          </v-card>
        </div>
      </v-timeline-item>
    </v-timeline></v-card
  >
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import ApiConfig from '../../../plugins/ApiConfig.js';
import SkeletonVersionList from './SkeletonVersionList';
// import DateFormat from "../../../plugins/DateFormat";
export default {
  components: {
    SkeletonVersionList,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    itemIdOpen: {
      type: String,
    },
    versionIdOpen: {
      type: String,
    },
    publishVersionNumber: {
      type: Number,
    },
  },
  computed: {
    ...mapState('auth', ['userInfo']),
    systemRoles() {
      return this.userInfo && this.userInfo.systemRole
        ? this.userInfo.systemRole
        : [];
    },
    isSystemAdmin() {
      var systemAdmin = false;
      for (let i = 0; i < this.systemRoles.length; i++) {
        if (this.systemRoles[i].name == 'System.Admin') {
          systemAdmin = true;
          break;
        }
      }
      return systemAdmin;
    },
  },
  data() {
    return {
      itemId: null,
      versions: [
        {
          // id: "288d9249-fa32-4c04-930f-927773b285f1",
          // versionNumber: 2,
          // isPreprocessing: false,
          // excutedModelCount: 0,
          // 另支API加入 finished: 0,
          // totalModelCount: 0,
          // createdUserId: "93037021-b753-4412-a4c5-40442cafbbc4",
          // createdUserName: "吳軒",
          // createdDate: "2021-01-20T10:55:58.750745+00:00",
          // modifiedUserId: "93037021-b753-4412-a4c5-40442cafbbc4",
          // modifiedUserName: "吳軒",
          // modifiedDate: "2021-01-20T10:55:58.750745+00:00",
        },
      ],
      originalVersions: [],
      dataState: {
        page: 0,
      },
      noImage: require('../../../assets/noImage.svg'),
      loadingVersion: true,
    };
  },
  watch: {
    itemIdOpen: {
      handler(val) {
        if (val) {
          this.itemId = val;
          this.loadVersions();
          this.sortVersions();
        }
      },
      deep: true,
    },
    publishVersionNumber: {
      handler() {
        this.loadVersions();
      },
      deep: true,
    },
  },
  mounted() {
    if (this.itemIdOpen) {
      this.itemId = this.itemIdOpen;
    } else {
      this.itemId = this.item.id;
    }
    this.loadVersions();
    this.sortVersions();
  },
  methods: {
    /**讀取點選item內的所有version */
    async loadVersions(upload, openVersion, apply) {
      return new Promise((resolve) => {
        var dataState = this.dataState;
        const queryStr = toDataSourceRequestString(dataState);
        var urlencoded = new URLSearchParams(queryStr);
        this.$API.api.pc.productItemVersion
          .get(this.itemId, urlencoded, ApiConfig.getJsonConfig)
          .then((res) => {
            this.originalVersions = res.data.data;
            this.versions = cloneDeep(this.originalVersions);
            this.addAction(apply);
            this.changeDateFormat();
            this.loadThumbnail();
            this.addFinised();
            if (upload) {
              this.addNewReload();
            }
            if (openVersion !== undefined) {
              this.openSpecVersion(openVersion);
            }
            this.loadingVersion = false;
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            resolve();
          });
      });
    },
    /**設定action和apply */
    addAction(apply) {
      var publishVersionNumber = this.item.publishVersionNumber;
      if (this.publishVersionNumber !== null) {
        publishVersionNumber = this.publishVersionNumber;
      }
      for (let i = 0; i < this.versions.length; i++) {
        if (this.versions[i].versionNumber == publishVersionNumber) {
          Vue.set(this.versions[i], 'apply', true);
          if (this.versionIdOpen == null) {
            Vue.set(this.versions[i], 'active', true);
            if (apply == undefined) {
              this.$emit('clickVersion', this.versions[i]);
            }
          }
        } else {
          Vue.set(this.versions[i], 'apply', false);
          Vue.set(this.versions[i], 'active', false);
        }
      }
      //若route有versionID 則開啟指定version
      if (this.versionIdOpen !== null) {
        for (let i = 0; i < this.versions.length; i++) {
          if (this.versions[i].id == this.versionIdOpen) {
            Vue.set(this.versions[i], 'active', true);
            if (apply == undefined) {
              this.$emit('clickVersion', this.versions[i]);
            }
          }
        }
      }
      if (
        this.item.publishVersionNumber == null &&
        this.versionIdOpen == null
      ) {
        //若無發布版本，則預設開啟最新版本
        Vue.set(this.versions[0], 'active', true);
        this.$emit('clickVersion', this.versions[0]);
      }
    },
    /**新增版本後開啟最新版本 */
    addNewReload() {
      Vue.set(this.versions[0], 'active', true);
      this.$emit('clickVersion', this.versions[0]);
      for (let i = 1; i < this.versions.length; i++) {
        Vue.set(this.versions[i], 'active', false);
      }
    },
    /**從模型轉檔列表開啟特定版本 */
    openSpecVersion(openVersion) {
      for (let i = 0; i < this.versions.length; i++) {
        if (this.versions[i].id == openVersion.id) {
          Vue.set(this.versions[i], 'active', true);
          this.$emit('clickVersion', this.versions[i]);
        } else {
          Vue.set(this.versions[i], 'active', false);
        }
      }
    },
    /**處理時間格式 */
    changeDateFormat() {
      for (let i = 0; i < this.versions.length; i++) {
        var date = new Date(this.versions[i].createdDate);
        var year = date.getFullYear();
        var month =
          date.getMonth() + 1 < 10
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1;
        var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        var hours =
          date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
        var minutes =
          date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
        var time = hours + ':' + minutes;
        day = year + '-' + month + '-' + day;
        Vue.set(this.versions[i], 'createdDay', day);
        Vue.set(this.versions[i], 'createdTime', time);
      }
    },
    /**取得版本縮圖 */
    async loadThumbnail() {
      for (let i = 0; i < this.versions.length; i++) {
        var versionId = this.versions[i].id;
        this.$API.api.pc.productVersion
          .getThumbnailBase64(versionId, ApiConfig.getJsonConfig)
          .then((res) => {
            var imageBase64 = 'data:image/png;base64,' + res.data;
            Vue.set(this.versions[i], 'image', imageBase64);
          })
          .catch((error) => {
            console.error(error);
            Vue.set(this.versions[i], 'image', 'notFound');
          })
          .finally(() => {});
      }
    },
    async addFinised() {
      for (let i = 0; i < this.versions.length; i++) {
        var version = this.versions[i];
        var versionId = version.id;
        await this.$API.api.pc.productVersion
          .getModelsStatus(versionId, ApiConfig.getJsonConfig)
          .then((res) => {
            if (res.data.Finished == undefined) {
              Vue.set(version, 'finished', 0);
            } else {
              Vue.set(version, 'finished', res.data.Finished);
            }
            if (!version.isPreprocessing) {
              var sum = this.sumObj(res.data);
              Vue.set(version, 'totalModelCount', sum);
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {});
      }
    },
    sumObj(obj) {
      var sum = 0;
      for (var el in obj) {
        sum += parseInt(obj[el]);
      }
      return sum;
    },
    sortVersions() {
      this.versions.sort(function (a, b) {
        return b.version - a.version;
      });
    },
    clickVersion(item) {
      for (let i = 0; i < this.versions.length; i++) {
        this.versions[i].active = false;
      }
      item.active = true;
      this.$emit('clickVersion', item);
    },
    clickNow() {
      for (let i = 0; i < this.versions.length; i++) {
        this.versions[i].active = false;
        if (this.versions[i].apply == true) {
          this.versions[i].active = true;
          this.$emit('clickVersion', this.versions[i]);
        }
      }
    },
    isAlllowedChanged(versionId) {
      console.log('isAlllowedChanged', versionId);
      for (let i = 0; i < this.versions.length; i++) {
        if (this.versions[i].id === versionId) {
          this.versions[i].isAllowedCreateOnDemand =
            !this.versions[i].isAllowedCreateOnDemand;
        }
      }
    },
    //下載版本資料，僅限系統管理者
    downloadVersion(item) {
      var itemId = item.id;
      var name = item.itemName;
      if (name.includes('.zip')) {
        name = name.substr(0, name.length - 4);
      }
      var fileName = name + '_' + item.versionNumber;

      this.$API.api.pc.productVersion
        .getOriginal(itemId, ApiConfig.getJsonConfig)
        .then((res) => {
          let blob = new Blob([res.data], {
            type: res.headers['content-type'],
          });
          let report = window.URL.createObjectURL(blob);

          let a = document.createElement('a');
          a.setAttribute('href', report);
          a.setAttribute('download', fileName);
          a.click();
        })
        .catch((error) => {
          console.error('packageDownload', error);
          this.$store.commit('notification/SET_DIALOG', {
            title: 'Error',
            message: error,
            value: true,
          });
        })
        .finally(() => {
          // this.downloadSnackbar = true;
          // setTimeout(() => (this.downloadSnackbar = false), 1000);
          let msg = '成功下載';
          this.$store.commit('notification/SET_ALERT', {
            type: 'success',
            message: msg,
            value: true,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.timelineTextCard {
  margin: 0px;
  padding: 0px;
  bottom: 1px;
  text-align: left;
}
.timelineText {
  font-weight: 700;
  font-size: 13px;
  color: #323232;
  text-align: left;
  padding: 0px;
}
.timelineTime {
  font-weight: 500;
  font-size: 13px;
  color: #323232;
  text-align: left;
  padding: 0px;
}
::v-deep .v-timeline-item__divider {
  position: relative;
  min-width: 45px;
  display: flex;
  align-items: center;
  justify-content: start;
}
.v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse):before {
  left: 62px;
  right: initial;
}
.theme--light.v-timeline .v-timeline-item .v-card::before {
  border-right-color: transparent;
}
</style>
