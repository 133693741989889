var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"overflow-y":"scroll","height":"100%"},attrs:{"loading":_vm.loading}},[_c('v-list',{staticClass:"pa-0",attrs:{"two-line":"","disabled":_vm.disabled}},[_c('v-list-item-group',{attrs:{"active-class":"primary--text"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._l((_vm.myGroups),function(item,index){return [_c('v-list-item',{key:item.index,style:(item.editOpen ? 'height: 145px' : 'height: 81px')},[_c('v-list-item-content',{staticClass:"pt-4 pb-4 pl-1"},[(item.editOpen)?_c('v-list-item-title',{staticClass:"mb-1",staticStyle:{"font-weight":"500"}},[_c('v-text-field',{attrs:{"label":"編輯群組名稱","hide-details":""},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.editName),callback:function ($$v) {_vm.editName=$$v},expression:"editName"}}),_c('v-btn',{staticClass:"mr-2 mt-3 mb-1",attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();item.editOpen = false}}},[_vm._v("取消")]),_c('v-btn',{staticClass:"mt-3 mb-1",attrs:{"small":"","depressed":"","dark":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.groupNameChange(item)}}},[_vm._v("確認更改")])],1):_vm._e(),(!item.editOpen)?_c('v-list-item-title',{staticClass:"mb-1",staticStyle:{"font-weight":"700"}},[_vm._v(_vm._s(item.name)+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"font-weight":"500","margin-top":"-10px"}},on),[_c('v-badge',{staticClass:"ma-0",attrs:{"content":item.itemsCount !== 0 ? item.itemsCount : '0',"inline":"","color":"#A0A0A0","dark":false}})],1)]}}],null,true)},[_c('span',[_vm._v("元件數量")])])],1):_vm._e(),(
                item.modifiedUserName !== null && item.modifiedUserName !== ''
              )?_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(
                '最後更新：' +
                  item.modifiedUserName +
                  ', ' +
                  item.modifiedDate
              )}}):_vm._e(),(
                item.modifiedUserName == null || item.modifiedUserName == ''
              )?_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s('無編輯紀錄')}}):_vm._e()],1),(!_vm.readOnly && !item.editOpen && item.id !== null)?_c('v-list-item-action',[_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){$event.stopPropagation();return _vm.editGroupName(item)}}},[_vm._v(" mdi-pencil ")])],1):_vm._e()],1),(index < _vm.myGroups.length)?_c('v-divider',{key:index}):_vm._e()]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }