var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pb-2",attrs:{"flat":"","color":"transparent"}},[_vm._l((_vm.myItems),function(item){return _c('v-card',{key:item.id,staticClass:"pa-0 ma-0",attrs:{"flat":"","color":"transparent"}},[_c('v-container',{staticClass:"pa-0 ma-0",staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"align":"center"}},[_c('v-card',{staticClass:"pa-0 ma-0",attrs:{"width":"80","flat":"","color":"transparent"}},[_c('v-checkbox',{staticClass:"ml-7",on:{"change":function($event){return _vm.check(item)}},model:{value:(item.check),callback:function ($$v) {_vm.$set(item, "check", $$v)},expression:"item.check"}})],1),_c('v-col',{staticClass:"pa-0 ma-0"},[_c('v-card',{staticClass:"mt-2 mr-2 pa-2",attrs:{"elevation":1,"color":item.check ? '#E2EEFC' : ''}},[_c('v-container',{staticClass:"pa-0 ma-0",staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"align":"center"}},[_c('v-card',{staticClass:"pa-1",attrs:{"outlined":"","width":"110","height":"110"}},[(item.image == undefined || item.image == null)?_c('v-skeleton-loader',{attrs:{"width":"100","max-height":"100","type":"image"}}):_vm._e(),(
                      item.image !== undefined &&
                        item.image !== null &&
                        item.image !== 'notFound'
                    )?_c('v-img',{attrs:{"lazy-src":item.image,"src":item.image,"contain":"","max-height":"100","max-width":"100"}}):_vm._e(),(item.image == 'notFound')?_c('v-img',{staticStyle:{"background-color":"#F0F0F0","opacity":"0.45"},attrs:{"lazy-src":_vm.noImage,"src":_vm.noImage,"contain":"","height":"100","width":"100"}}):_vm._e()],1),_c('v-col',{staticClass:"ma-0 pa-0"},[_c('v-card',{staticClass:"pt-2",attrs:{"flat":"","color":"transparent","height":"100"}},[_c('v-card-text',{class:item.check
                          ? 'pt-2 pb-1 primary--text'
                          : 'pt-2 pb-1 black--text',staticStyle:{"font-weight":"600","font-size":"18px"}},[_vm._v(_vm._s(item.name)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [(!item.isPublic)?_c('v-icon',_vm._g({staticStyle:{"margin-top":"-3px","margin-left":"1px","margin-right":"2px"},attrs:{"small":""}},on),[_vm._v("mdi-lock")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("權限不公開")])]),(item.publishVersionId !== null)?_c('v-btn',{staticStyle:{"margin-top":"-4px","margin-left":"4px"},attrs:{"outlined":"","color":"primary","disabled":"","width":"56","small":"","id":"custom-disabled"}},[_vm._v("版本 "+_vm._s(item.publishVersionNumber)+" ")]):_vm._e(),(item.publishVersionNumber == null)?_c('v-btn',{staticStyle:{"margin-top":"-4px","margin-left":"4px"},attrs:{"outlined":"","color":"primary","disabled":"","width":"56","small":"","id":"custom-disabledPublished"}},[_vm._v("未發布 ")]):_vm._e()],1),_c('v-card-text',{staticClass:"pb-0 pt-4"},[_vm._v("查閱次數："),(item.viewCounts !== undefined)?_c('v-chip',{staticStyle:{"font-weight":"600"},attrs:{"small":""}},[_vm._v(" "+_vm._s(item.viewCounts)+" ")]):_vm._e(),(item.viewCounts == undefined)?_c('v-progress-circular',{staticClass:"mt-1 mr-3",attrs:{"size":18,"width":3,"color":"#B1B1B1","indeterminate":""}}):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}),(!_vm.loading && _vm.myItems.length == 0)?_c('v-card',{attrs:{"flat":"","color":"transparent"}},[_c('EmptyCard')],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }