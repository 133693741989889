<template>
  <v-card flat color="transparent">
    <v-card
      v-for="item in myItems"
      :key="item.id"
      :elevation="1"
      class="ma-2 pa-2"
      :color="!item.isEnable ? '#F0F0F0' : ''"
    >
      <v-container fluid class="pa-0 ma-0" style="height:100%">
        <v-row class="pa-0 ma-0" align="center">
          <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on }"> -->
          <v-card
            outlined
            width="110"
            height="110"
            class="pa-1"
            @click="viewVersions(item)"
          >
            <v-skeleton-loader
              v-if="item.image == undefined || item.image == null"
              width="100"
              max-height="100"
              type="image"
            ></v-skeleton-loader>
            <v-img
              v-if="
                item.image !== undefined &&
                  item.image !== null &&
                  item.image !== 'notFound'
              "
              :lazy-src="item.image"
              :src="item.image"
              contain
              max-height="100"
              max-width="100"
            ></v-img>
            <v-img
              v-if="item.image == 'notFound'"
              :lazy-src="noImage"
              :src="noImage"
              style="background-color: #F0F0F0; opacity: 0.45"
              contain
              height="100"
              width="100"
            ></v-img
          ></v-card>
          <!-- </template>
          <span>查閱版本</span>
        </v-tooltip> -->
          <v-col cols="5" class="ma-0 pa-0">
            <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }"> -->
            <v-card
              flat
              color="transparent"
              height="100"
              class="pt-2"
              @click="viewVersions(item)"
            >
              <v-card-text
                style="font-weight: 600; font-size:18px; margin-top: 6px"
                class="pt-0 pb-1 black--text"
                ><span class="mr-2">{{ item.name }}</span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-if="!item.isPublic"
                      v-on="on"
                      small
                      style="margin-top: -3px; margin-left: 1px;margin-right: 8px"
                      >mdi-lock</v-icon
                    ></template
                  >
                  <span>權限不公開</span>
                </v-tooltip>
                <v-btn
                  v-if="item.publishVersionId !== null"
                  outlined
                  color="primary"
                  disabled
                  width="56"
                  small
                  id="custom-disabled"
                  style="margin-top:0px; "
                  >版本 {{ item.publishVersionNumber }}
                </v-btn>
                <v-btn
                  v-if="item.publishVersionNumber == null"
                  outlined
                  color="primary"
                  disabled
                  width="56"
                  small
                  id="custom-disabledPublished"
                  style="margin-top:0px; "
                  >未發布
                </v-btn>
              </v-card-text>
              <v-card-text class="pb-0 pt-2"
                >查閱次數：<v-chip
                  v-if="item.viewCounts !== undefined"
                  style="font-weight: 600;"
                  small
                >
                  {{ item.viewCounts }}
                </v-chip>
                <v-progress-circular
                  v-if="item.viewCounts == undefined"
                  :size="18"
                  :width="3"
                  color="#B1B1B1"
                  indeterminate
                  class="mt-1 mr-3"
                ></v-progress-circular></v-card-text
            ></v-card>
            <!-- </template>
            <span>查閱版本</span>
          </v-tooltip> -->
          </v-col>

          <v-divider vertical />

          <v-col cols="1" align="center" class="ma-0 pa-0">
            <v-tooltip bottom v-if="item.isProcessing">
              <template v-slot:activator="{ on }">
                <v-progress-circular
                  v-on="on"
                  :size="30"
                  :width="4"
                  color="#A9A9A9"
                  indeterminate
                ></v-progress-circular
              ></template>
              <span>處理中</span>
            </v-tooltip>
          </v-col>
          <v-col cols="2" align="center" class="ma-0 ml-3 pa-0">
            <v-card flat color="transparent">
              <v-btn
                @click="getLink()"
                text
                :color="!item.isEnable ? '' : 'primary'"
                :disabled="!item.isEnable"
                ><v-icon class="mr-1">mdi-link</v-icon>取得分享碼</v-btn
              >
            </v-card>
          </v-col>
          <v-col class="pa-0" align="right">
            <v-card flat color="transparent">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    large
                    icon
                    color="error"
                    v-on="on"
                    @click="deleteItem(item)"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </template>
                <span>刪除</span>
              </v-tooltip>
              <v-menu offset-y left>
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        large
                        icon
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                      >
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                    </template>

                    <span>進階設定</span>
                  </v-tooltip></template
                >
                <v-card flat>
                  <AdvancedMenu :item="item" @reload="enableReload" /> </v-card
              ></v-menu>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn large icon v-on="on" @click="viewVersions(item)"
                    ><v-icon>mdi-chevron-right</v-icon></v-btn
                  >
                </template>

                <span>查閱版本</span>
              </v-tooltip>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card v-if="!loading && myItems.length == 0" flat color="transparent"
      ><EmptyCard
    /></v-card>
    <div v-if="loading" class="text-center pa-2">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-dialog v-model="deleteDialog.value" max-width="400"
      ><DeleteDialog
        :deleteItem="deleteDialog.deleteItem"
        @delDialogClose="deleteDialog.value = false"
        @deleteItemConfirm="deleteItemConfirm"/></v-dialog
  ></v-card>
</template>

<script>
import ApiConfig from '../../../plugins/ApiConfig';
import AdvancedMenu from './AdvancedMenu';
import DeleteDialog from '../../Dialog/DeleteItemDialog';
import EmptyCard from './EmptyCard';
export default {
  components: {
    AdvancedMenu,
    DeleteDialog,
    EmptyCard,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    itemIdOpen: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
  },
  watch: {
    items: {
      handler() {
        this.loadItems();
      },
      deep: true,
    },
  },
  data() {
    return {
      myItems: [],
      noImage: require('../../../assets/noImage.svg'),
      deleteDialog: {
        value: false,
        deleteItem: {},
      },
    };
  },
  async mounted() {
    await this.loadItems();
    if (this.itemIdOpen !== null) {
      this.openSpecVersion();
    }
  },
  methods: {
    viewVersions(item) {
      this.$emit('viewVersions', item);
    },
    /**透過route開啟 */
    openSpecVersion() {
      for (let i = 0; i < this.myItems.length; i++) {
        if (this.myItems[i].id === this.itemIdOpen) {
          this.myItems[i].active = true;
          this.$emit('viewVersions', this.myItems[i]);
        }
      }
    },
    loadItems() {
      var myItems = [];
      for (let i = 0; i < this.items.length; i++) {
        if (!this.items[i].isEnable) {
          myItems.push(this.items[i]);
        }
      }
      this.myItems = myItems;
    },
    getLink() {
      let msg = '已複製連結';
      this.$store.commit('notification/SET_ALERT', {
        type: 'success',
        message: msg,
        value: true,
      });
    },
    deleteItem(item) {
      this.deleteDialog.value = true;
      this.deleteDialog.deleteItem = item;
    },
    deleteItemConfirm() {
      this.deleteDialog.value = false;

      var itemId = this.deleteDialog.deleteItem.id;
      this.$API.api.pc.productItem
        .delete(itemId, ApiConfig.getJsonConfig)
        .then(() => {
          let message = this.$i18n.t('form.alert.deleteFormResult', {
            name: name,
          });
          this.$store.commit('notification/SET_ALERT', {
            type: 'success',
            message: message,
            value: true,
          });
        })
        .catch((error) => {
          console.error('deleteFormResult', error);
          this.$store.commit('notification/SET_DIALOG', {
            title: 'Error',
            message: error,
            value: true,
          });
        })
        .finally(() => {
          this.$emit('reload');
        });
    },
    enableReload() {
      this.$emit('reload');
    },
  },
};
</script>
