<template>
  <v-card flat color="transparent">
    <v-card
      v-for="item in myItems"
      :key="item.id"
      :elevation="1"
      class="ma-2 pa-2"
      :color="item.active ? '#E2EEFC' : ''"
      ><v-container fluid class="pa-0 ma-0" style="height: 100%">
        <v-row class="pa-0 ma-0" align="center">
          <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on }"> -->
          <v-card
            outlined
            width="110"
            height="110"
            class="pa-1"
            @click="viewVersions(item)"
          >
            <v-skeleton-loader
              v-if="item.image == undefined || item.image == null"
              width="100"
              max-height="100"
              type="image"
            ></v-skeleton-loader>
            <v-img
              v-if="
                item.image !== undefined &&
                item.image !== null &&
                item.image !== 'notFound'
              "
              :lazy-src="item.image"
              :src="item.image"
              contain
              max-height="100"
              max-width="100"
            ></v-img>
            <v-img
              v-if="item.image == 'notFound'"
              :lazy-src="noImage"
              :src="noImage"
              style="background-color: #f0f0f0; opacity: 0.45"
              contain
              height="100"
              width="100"
            ></v-img>
          </v-card>
          <!-- </template>
          <span>查閱版本</span>
        </v-tooltip> -->
          <v-col :cols="readOnly ? 8 : 5" class="ma-0 pa-0">
            <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }"> -->
            <v-card
              flat
              color="transparent"
              height="100"
              class="pt-2"
              @click="viewVersions(item)"
            >
              <v-card-text
                style="font-weight: 600; font-size: 18px; margin-top: 6px"
                :class="
                  item.active
                    ? 'pt-0 pb-1 primary--text'
                    : 'pt-0 pb-1 black--text'
                "
                ><span class="mr-2">{{ item.name }}</span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-if="!item.isPublic"
                      v-on="on"
                      small
                      style="
                        margin-top: -3px;
                        margin-left: 1px;
                        margin-right: 8px;
                      "
                      >mdi-lock</v-icon
                    ></template
                  >
                  <span>權限不公開</span>
                </v-tooltip>
                <v-btn
                  v-if="item.publishVersionId !== null"
                  outlined
                  color="primary"
                  disabled
                  width="56"
                  small
                  id="custom-disabled"
                  style="margin-top: 0px"
                  >版本 {{ item.publishVersionNumber }}
                </v-btn>
                <v-btn
                  v-if="item.publishVersionNumber == null"
                  outlined
                  color="primary"
                  disabled
                  width="56"
                  small
                  id="custom-disabledPublished"
                  style="margin-top: 0px"
                  >未發布
                </v-btn>
              </v-card-text>
              <v-card-text class="pb-0 pt-2"
                >查閱次數：<v-chip
                  v-if="item.viewCounts !== undefined"
                  style="font-weight: 600"
                  small
                >
                  {{ item.viewCounts }}
                </v-chip>
                <v-progress-circular
                  v-if="item.viewCounts == undefined"
                  :size="18"
                  :width="3"
                  color="#B1B1B1"
                  indeterminate
                  class="mt-1 mr-3"
                ></v-progress-circular></v-card-text
            ></v-card>
          </v-col>

          <v-divider v-if="!readOnly" vertical />
          <v-col cols="1" v-if="!readOnly" align="center" class="ma-0 pa-0">
            <v-tooltip bottom v-if="item.isProcessing">
              <template v-slot:activator="{ on }">
                <v-progress-circular
                  v-on="on"
                  :size="25"
                  :width="4"
                  :rotate="-90"
                  :value="value"
                  :color="loadingColor"
                ></v-progress-circular
              ></template>
              <span>處理中</span>
            </v-tooltip>
          </v-col>
          <v-col
            :cols="readOnly ? '' : '2'"
            :align="readOnly ? 'end' : 'center'"
            class="ma-0 ml-3 pa-0"
          >
            <v-card flat color="transparent">
              <v-btn
                class="pa-1"
                @click="getLink(item)"
                text
                :color="!item.isEnable ? '' : 'primary'"
                :disabled="item.link == null"
                ><v-icon class="mr-1">mdi-link</v-icon>取得分享碼</v-btn
              >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }"
                  ><v-btn
                    :id="
                      item.active
                        ? 'custom-openLinkDisabledActive'
                        : 'custom-openLinkDisabled'
                    "
                    fab
                    x-small
                    elevation="0"
                    v-on="on"
                    :color="item.active ? '#E2EEFC' : 'white'"
                    :disabled="item.link == null"
                    @click.stop="openLink(item)"
                    ><v-icon color="primary">mdi-open-in-new</v-icon></v-btn
                  ></template
                >

                <span>前往</span>
              </v-tooltip>
            </v-card>
          </v-col>
          <v-col v-if="!readOnly" class="pa-0" align="right">
            <v-card flat color="transparent">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    large
                    icon
                    color="error"
                    v-on="on"
                    @click="deleteItem(item)"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </template>

                <span>刪除</span>
              </v-tooltip>
              <v-menu offset-y left>
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        large
                        icon
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                      >
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                    </template>

                    <span>進階設定</span>
                  </v-tooltip></template
                >
                <v-card flat>
                  <AdvancedMenu
                    :item="item"
                    :admin="admin"
                    :oneDriveSet="oneDriveSet"
                    @moveItem="moveItem"
                    @reload="enableReload"
                    @createLink="createLink"
                    @clearLink="clearLink"
                    @exportItem="exportItem"
                  /> </v-card
              ></v-menu>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn large icon v-on="on" @click="viewVersions(item)"
                    ><v-icon>mdi-chevron-right</v-icon></v-btn
                  >
                </template>

                <span>查閱版本</span>
              </v-tooltip>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card v-if="!loading && myItems.length == 0" flat color="transparent"
      ><EmptyCard
    /></v-card>
    <div v-if="loading" class="text-center pa-2">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-dialog v-model="deleteDialog.value" max-width="400"
      ><DeleteDialog
        :deleteItem="deleteDialog.deleteItem"
        @delDialogClose="deleteDialog.value = false"
        @deleteItemConfirm="deleteItemConfirm"
    /></v-dialog>
    <DownloadSnackbar :downloadSnackbar="downloadSnackbar" />
  </v-card>
</template>

<script>
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { mapState } from 'vuex';
import ApiConfig from '../../../plugins/ApiConfig';
import AdvancedMenu from './AdvancedMenu';
import DeleteDialog from '../../Dialog/DeleteItemDialog';
import DownloadSnackbar from '../../DownloadSnackbar.vue';
import EmptyCard from './EmptyCard';
export default {
  components: {
    AdvancedMenu,
    DeleteDialog,
    EmptyCard,
    DownloadSnackbar,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    searchItemArr: {
      type: Array,
    },
    itemIdOpen: {
      type: String,
    },
    readOnly: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
  },
  watch: {
    items: {
      handler() {
        this.loadItems();
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (val % 100 == 0) {
          this.count += 1;
          if (this.count % 2 == 1) {
            this.loadingColor = '#E0EDFF';
          } else {
            this.loadingColor = 'primary';
          }
        }
      },
      deep: true,
    },
    searchItemArr: {
      handler() {
        this.loadItems();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState('auth', ['system', 'userInfo']),
    admin() {
      var adminCheck = false;
      var functions = this.system.functions;
      for (let i = 0; i < functions.length; i++) {
        if (
          functions[i].name ==
          'System.ProductConfigurator.ProductProcessingUsage.Manage'
        ) {
          adminCheck = true;
        }
      }
      return adminCheck;
    },
    oneDriveSet() {
      var oneDriveUrl = false;
      if (
        this.userInfo.oneDriveSyncUrl !== '' &&
        this.userInfo.oneDriveSyncUrl !== null
      ) {
        oneDriveUrl = true;
      }
      return oneDriveUrl;
    },
  },
  data() {
    return {
      downloadSnackbar: false,
      myItems: [],
      noImage: require('../../../assets/noImage.svg'),
      deleteDialog: {
        value: false,
        deleteItem: {},
      },
      interval: {},
      value: 0,
      loadingColor: 'primary',
      count: 0,
    };
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  async mounted() {
    await this.loadItems();
    if (this.itemIdOpen !== null) {
      this.openSpecVersion();
    }
    this.interval = setInterval(() => {
      if (this.value === 100) {
        return (this.value = 0);
      }
      this.value += 20;
    }, 500);
  },
  methods: {
    exportItem(item) {
      var itemId = item.id;
      this.$API.api.pc.productItem
        .packageDownload(itemId, ApiConfig.getJsonConfig)
        .then(() => {})
        .catch((error) => {
          console.error('packageDownload', error);
          this.$store.commit('notification/SET_DIALOG', {
            title: 'Error',
            message: error,
            value: true,
          });
        })
        .finally(() => {
          this.downloadSnackbar = true;
          setTimeout(() => (this.downloadSnackbar = false), 1000);
        });
    },
    viewVersions(item) {
      for (let i = 0; i < this.myItems.length; i++) {
        this.myItems[i].active = false;
      }
      item.active = true;
      this.$emit('viewVersions', item);
    },
    /**透過route開啟 */
    openSpecVersion() {
      for (let i = 0; i < this.myItems.length; i++) {
        if (this.myItems[i].id === this.itemIdOpen) {
          this.myItems[i].active = true;
          this.$emit('viewVersions', this.myItems[i]);
        }
      }
    },
    loadItems() {
      var myItems = [];
      var items = cloneDeep(this.items);
      for (let i = 0; i < items.length; i++) {
        Vue.set(items[i], 'active', false);
        if (this.items[i].isEnable) {
          myItems.push(items[i]);
        }
      }
      //搜尋具關鍵字的元件
      if (this.searchItemArr.length > 0) {
        for (let i = 0; i < myItems.length; i++) {
          for (let j = 0; j < this.searchItemArr.length; j++) {
            if (myItems[i].id === this.searchItemArr[j].id) {
              myItems[i].active = true;
            }
          }
        }
      }

      this.myItems = myItems;
    },
    moveItem(item) {
      this.$emit('moveItem', item);
    },
    getLink(item) {
      const clipboardy = require('clipboardy');
      clipboardy.write(item.shareCode);
      let msg = '已複製連結';
      this.$store.commit('notification/SET_ALERT', {
        type: 'success',
        message: msg,
        value: true,
      });
    },
    deleteItem(item) {
      this.deleteDialog.value = true;
      this.deleteDialog.deleteItem = item;
    },
    deleteItemConfirm() {
      this.deleteDialog.value = false;

      var itemId = this.deleteDialog.deleteItem.id;
      this.$API.api.pc.productItem
        .delete(itemId, ApiConfig.getJsonConfig)
        .then(() => {})
        .catch((error) => {
          console.error('deleteFormResult', error);
          this.$store.commit('notification/SET_DIALOG', {
            title: 'Error',
            message: error,
            value: true,
          });
        })
        .finally(() => {
          this.$emit('reload', true);
          let message = this.$i18n.t('form.alert.deleteFormResult', {
            name: name,
          });
          this.$store.commit('notification/SET_ALERT', {
            type: 'success',
            message: message,
            value: true,
          });
        });
    },
    enableReload() {
      this.$emit('reload');
    },
    clearActive() {
      for (let i = 0; i < this.myItems.length; i++) {
        this.myItems[i].active = false;
      }
    },
    openLink(item) {
      window.open(item.link);
    },
    createLink(item, link) {
      const str = link.split('/');
      var shareCode = str[str.length - 1];
      for (let i = 0; i < this.myItems.length; i++) {
        if (this.myItems[i].id === item.id) {
          this.myItems[i].link = link;
          this.myItems[i].shareCode = shareCode;
        }
      }
    },
    clearLink(item) {
      for (let i = 0; i < this.myItems.length; i++) {
        if (this.myItems[i].id === item.id) {
          this.myItems[i].link = null;
          this.myItems[i].shareCode = null;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#custom-disabled.v-btn--disabled {
  color: #006ad8 !important;
}
#custom-disabledPublished.v-btn--disabled {
  color: #7a7a7a !important;
}
#custom-openLinkDisabled.v-btn--disabled {
  background-color: white !important;
}
#custom-openLinkDisabledActive.v-btn--disabled {
  background-color: #e2eefc !important;
}
</style>
